import React from "react";
import { useRouter } from "next/router";
import { pageview } from ".";

const usePageviewLogger = () => {
    const router = useRouter();

    React.useEffect(() => {
        const handleRouteChange = (route: string) => {
            pageview(route);
        };

        router.events.on("routeChangeComplete", handleRouteChange);

        // If the component is unmounted, unsubscribe from router events
        return () => {
            router.events.off("routeChangeComplete", handleRouteChange);
        };
    }, [router.events]);
};

export default usePageviewLogger;
