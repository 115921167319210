import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import React from "react";

export interface DialogProps {
    title?: string;
    message: string;
    severity: "info" | "warning" | "error";
    confirmText?: string;
    cancelText?: string;
}

interface DialogPropsWithHandlers extends DialogProps {
    onClose: () => void;
    onConfirm: (success: boolean) => void;
}

const ConfirmationDialog: React.FC<DialogPropsWithHandlers> = ({
    title,
    message,
    onConfirm,
    onClose,
    severity,
    confirmText,
    cancelText,
}) => {
    return (
        <Dialog open onClose={onClose}>
            {title ? <DialogTitle>{title}</DialogTitle> : null}
            <DialogContent> {message}</DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="inherit"
                    onClick={() => onConfirm(false)}
                >
                    {cancelText ? cancelText : "Cancel"}
                </Button>
                <Button
                    variant="contained"
                    color={severity}
                    onClick={() => onConfirm(true)}
                >
                    {confirmText ? confirmText : "Confirm"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDialog;
