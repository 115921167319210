export enum SupportedCurrency {
    EUR = "EUR",
    DKK = "DKK",
    USD = "USD",
    GBP = "GBP",
    CAD = "CAD",
    MXN = "MXN",
    AUD = "AUD",
    SGD = "SGD",
    SEK = "SEK",
}
