import React from "react";
import { ReactNode, type ReactPortal } from "react";
import { createPortal } from "react-dom";

const ReactPortal = ({
    children,
    wrapperId = "react-portal-wrapper",
}: {
    children: ReactNode;
    wrapperId: string;
}): ReactPortal | null => {
    const [wrapperElement, setWrapperElement] =
        React.useState<HTMLElement | null>(null);

    React.useEffect(() => {
        let element = document.getElementById(wrapperId);
        let systemCreated = false;
        if (!element) {
            systemCreated = true;
            element = createWrapperAndAppendToBody(wrapperId);
        }
        setWrapperElement(element);

        return () => {
            // delete the programatically created element
            if (systemCreated && element?.parentNode) {
                element.parentNode.removeChild(element);
            }
        };
    }, [wrapperId]);

    return wrapperElement ? createPortal(children, wrapperElement) : null;
};

const createWrapperAndAppendToBody = (wrapperId: string) => {
    const wrapperElement = document.createElement("div");
    wrapperElement.setAttribute("id", wrapperId);
    document.body.appendChild(wrapperElement);
    return wrapperElement;
};

export default ReactPortal;
