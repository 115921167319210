import React from "react";
import {
    createTheme,
    ThemeOptions,
    ThemeProvider as MUIThemeProvider,
} from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { ThemeProvider as EmotionThemeProvider } from "@emotion/react";

import palette from "./palette";
import { shadows, customShadows } from "./shadows";
import typography from "./typography";
import breakpoints from "./breakpoints";
import componentsOverride from "./overrides";

const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const themeOptions: ThemeOptions = React.useMemo(
        () => ({
            palette: palette.light,
            typography,
            breakpoints,
            shape: { borderRadius: 8 },
            shadows: shadows.light,
            customShadows: customShadows.light,
        }),
        [],
    );

    const theme = createTheme(themeOptions);
    theme.components = componentsOverride(theme);

    return (
        <MUIThemeProvider theme={theme}>
            <EmotionThemeProvider theme={theme}>
                <CssBaseline />
                {children}
            </EmotionThemeProvider>
        </MUIThemeProvider>
    );
};

export default ThemeProvider;
