import Head from "next/head";
import ThemeProvider from "@theme/index";
import { CssBaseline } from "@mui/material";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "@lib/firebase-internal/clientApp";
import React from "react";
import { IntercomProvider, useIntercom } from "react-use-intercom";
import { useRouter } from "next/router";
import usePageviewLogger from "@lib/googleAnalytics/usePageviewLogger";
import useUpdateUserId from "@lib/googleAnalytics/useUpdateUserId";
import { ConfirmationDialogContextProvider } from "@utils/confirmationDialog/confirmationDialogContext";
import Script from "next/script";
import { ImpersonationContextProvider } from "@hooks/campaign-manager/useImpersonation";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import { clientOptions } from "@lib/utils/gql/gqlClient";
import { withUrqlClient } from "next-urql";
import { SnackbarContextProvider } from "@hooks/useSnackbar";
import { AppPropsWithLayout } from "@lib/types/next";
import {
    FILIFY_CAMPAIGN_AFFILIATE_ID_KEY,
    FILIFY_CAMPAIGN_OFFER_ID_KEY,
    FILIFY_CAMPAIGN_TRANSACTION_ID_KEY,
} from "@lib/constants";
import { LicenseInfo } from "@mui/x-license-pro";
import { User } from "firebase/auth";
import { graphql } from "@lib/gql";
import { useQuery } from "urql";
import { CampaignRequestStatus } from "@lib/gql/graphql";
import useRedirectCompanyToBilling from "@hooks/useRedirectCompanyToBilling";

const MUI_LICENSE_KEY = process.env.NEXT_PUBLIC_MUI_LICENSE_KEY;
const INTERCOM_APP_ID = process.env.NEXT_PUBLIC_INTERCOM_APP_ID;
const HOT_JAR_ENABLED = process.env.NODE_ENV === "production";

LicenseInfo.setLicenseKey(MUI_LICENSE_KEY || "");

// only initialize when in the browser
if (typeof window !== "undefined") {
    LogRocket.init("gkctv6/filify-client");
    // plugins should also only be initialized when in the browser
    setupLogRocketReact(LogRocket);
}

const RequestedCampaignStatusQuery = graphql(`
    query RequestedCampaignStatus {
        me {
            requestedCampaigns {
                status
            }
        }
    }
`);

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
    const [user] = useAuthState(auth);

    const { update } = useIntercom();
    const { query } = useRouter();
    const [{ data: requestedCampaignStatus }] = useQuery({
        query: RequestedCampaignStatusQuery,
    });

    useRedirectCompanyToBilling();
    usePageviewLogger();
    useUpdateUserId();

    React.useEffect(() => {
        if (typeof window === "undefined") return;

        if (typeof query.tune === "string")
            localStorage.setItem(
                FILIFY_CAMPAIGN_TRANSACTION_ID_KEY,
                query.tune,
            );
        if (typeof query.tuneOfferId === "string")
            localStorage.setItem(
                FILIFY_CAMPAIGN_OFFER_ID_KEY,
                query.tuneOfferId,
            );
        if (typeof query.tuneAffId === "string")
            localStorage.setItem(
                FILIFY_CAMPAIGN_AFFILIATE_ID_KEY,
                query.tuneAffId,
            );
    }, [query]);

    const getRequestCount = React.useCallback(async () => {
        return requestedCampaignStatus?.me.requestedCampaigns.reduce(
            (acc, curr) => {
                switch (curr.status) {
                    case CampaignRequestStatus.Active:
                        acc.active++;
                        break;
                    case CampaignRequestStatus.Pending:
                        acc.requested++;
                        break;
                    default:
                        break;
                }
                acc.total++;
                return acc;
            },
            { total: 0, active: 0, requested: 0 },
        );
    }, [requestedCampaignStatus?.me.requestedCampaigns]);

    React.useEffect(() => {
        const updateInterom = async (user: User) => {
            const counts = await getRequestCount();
            update({
                name: user.displayName ?? "",
                email: user.email ?? "",
                createdAt: user.metadata.creationTime,
                userId: user.uid,
                customAttributes: {
                    "active campaigns": counts?.active ?? 0,
                    "requested campaigns": counts?.requested ?? 0,
                    "total campaigns": counts?.total ?? 0,
                },
            });
        };

        if (user) {
            updateInterom(user);
            LogRocket.identify(user.uid, {
                name: user.displayName ?? "",
                email: user.email ?? "",
                createdAt: user.metadata.creationTime ?? "",
            });
        } else {
            update();
        }
    }, [update, user, getRequestCount]);

    const getLayout = Component.getLayout ?? (page => page);

    return (
        <>
            <Head>
                <link
                    rel="icon"
                    type="image/svg+xml"
                    href="/favicon.svg"
                    sizes="any"
                />
            </Head>
            {HOT_JAR_ENABLED ? (
                <Script
                    id="hot-jar"
                    strategy="afterInteractive"
                    dangerouslySetInnerHTML={{
                        __html: `
                    <!-- Hotjar Tracking Code for https://a.filify.app -->
                    (function(h,o,t,j,a,r){
                        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                        h._hjSettings={hjid:3057774,hjsv:6};
                        a=o.getElementsByTagName('head')[0];
                        r=o.createElement('script');r.async=1;
                        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                        a.appendChild(r);
                    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
                    `,
                    }}
                />
            ) : null}
            <ThemeProvider>
                <CssBaseline />
                <ConfirmationDialogContextProvider>
                    <SnackbarContextProvider>
                        <ImpersonationContextProvider>
                            {getLayout(<Component {...pageProps} />)}
                        </ImpersonationContextProvider>
                    </SnackbarContextProvider>
                </ConfirmationDialogContextProvider>
            </ThemeProvider>
        </>
    );
}

const IntercomWrappedApp = (props: AppPropsWithLayout) =>
    INTERCOM_APP_ID ? (
        <IntercomProvider appId={INTERCOM_APP_ID} autoBoot>
            <MyApp {...props} />
        </IntercomProvider>
    ) : (
        <MyApp {...props} />
    );

export default withUrqlClient(() => clientOptions)(IntercomWrappedApp);
