import { graphql } from "@lib/gql";
import React from "react";
import { useQuery } from "urql";
import useAuthRoles from "./roles/useAuthRoles";
import { CompanySubscription } from "@lib/gql/graphql";

export const GetCompanyWithSubscription = graphql(`
    query GetCompanyWithSubscription($companyId: Id!) {
        getCompany(companyId: $companyId) {
            subscription {
                stripeCustomerId
                stripeSubscriptionId
                productId
                signUpMail
                subscriptionEndDate
            }
        }
    }
`);

const useCompanySubscription = (): [
    subscription: CompanySubscription | undefined | null,
    fetching: boolean,
] => {
    const rolesInfo = useAuthRoles();
    const companyId = React.useMemo(
        () => rolesInfo?.find(r => r.info?.companyId)?.info?.companyId,
        [rolesInfo],
    );

    const [{ data, fetching }] = useQuery({
        query: GetCompanyWithSubscription,
        variables: {
            companyId: companyId ?? "",
        },
        pause: !companyId,
    });

    return [data?.getCompany?.subscription, fetching];
};

export default useCompanySubscription;
