import React from "react";
import {
    Avatar,
    Card,
    Link,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import {
    campaignRequestStatusColor,
    campaignRequestStatusLabel,
} from "@utils/campaignStatus";
import { Label } from "@components/mui/Label";
import {
    combineMultiCurrencies,
    commissionToString,
    moneyToString,
    percentageToString,
} from "@utils/currency";
import { CopyableToClipboard } from "./CopyableToClipboard";
import NextLink from "next/link";
import MobileCampaignsOverview from "./mobileOverview";
import { useCommissionFormatter } from "@hooks/useCommissionFormatter";
import { createCampaignStatsLink } from "@utils/links";
import useUsersCampaignData from "@hooks/useUsersCampaignData";
import { useQuery } from "urql";
import { graphql } from "@lib/gql";
import {
    CampaignRequestStatus,
    GetMyCampaignRequestsQuery as GetMyCampaignRequestsQueryType,
    MultiCurrencyMoney,
} from "@lib/gql/graphql";
import { Timeframe } from "./campaignsPerformanceChart";

export type CampaignRow = {
    id: string;
    campaignRequest: GetMyCampaignRequestsQueryType["me"]["requestedCampaigns"][number];
    stats: {
        earnings: MultiCurrencyMoney;
        clicks: number;
        conversions: number;
        conversionRate: number;
    };
};

const columns: GridColDef<CampaignRow>[] = [
    {
        field: "campaign",
        headerName: "Campaign",
        width: 200,
        valueGetter: params => params.row.campaignRequest.campaign.title,
        renderCell: params => {
            const campaign = params.row.campaignRequest.campaign;
            const link = createCampaignStatsLink(campaign.id);
            return (
                <NextLink href={link} passHref legacyBehavior>
                    <Link width="100%" color="inherit" noWrap>
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <Avatar
                                src={campaign.company.imageUrl}
                                alt={campaign.title}
                            />
                            <Typography variant="subtitle2">
                                {campaign.title}
                            </Typography>
                        </Stack>
                    </Link>
                </NextLink>
            );
        },
    },
    {
        field: "earnings",
        headerName: "Earnings",
        valueGetter: params => {
            const { currencies } = params.row.stats.earnings;

            if (currencies.length === 0) {
                return "-";
            }

            return currencies.map(m => moneyToString(m)).join("\n");
        },
    },
    {
        field: "clicks",
        headerName: "Clicks",
        valueGetter: params => params.row.stats.clicks,
    },
    {
        field: "cpa",
        headerName: "Commission",
        valueGetter: params => {
            const { currentCommission } = params.row.campaignRequest;
            const campaignCommission =
                params.row.campaignRequest.campaign.commissionInfo
                    .currentCommission;
            return currentCommission ? currentCommission : campaignCommission;
        },
        valueFormatter: params => {
            const commission = params.value;
            return commissionToString(commission);
        },
    },
    {
        field: "conversions",
        headerName: "Conversions",
        valueGetter: params => params.row.stats.conversions,
    },
    {
        field: "status",
        headerName: "Status",
        width: 80,
        renderCell: params => {
            const { status } = params.row.campaignRequest;
            return (
                <Label color={campaignRequestStatusColor(status)}>
                    {campaignRequestStatusLabel(status)}
                </Label>
            );
        },
    },
    {
        field: "conversionRate",
        headerName: "CR %",
        width: 70,
        valueGetter: params => params.row.stats.conversionRate,
        valueFormatter: params =>
            params.value ? percentageToString(Number(params.value)) : "",
    },
    {
        field: "referralLink",
        headerName: "Affiliation Link",
        width: 220,
        renderCell: params => {
            const isActive =
                params.row.campaignRequest.status ===
                CampaignRequestStatus.Active;
            const link = params.row.campaignRequest.trackingLink;
            return isActive && link && <CopyableToClipboard text={link} />;
        },
    },
    {
        field: "contactEmail",
        headerName: "Contact Email",
        width: 200,
        renderCell: params => {
            const email = params.row.campaignRequest.campaign.contactEmail;
            return email && <CopyableToClipboard text={email} />;
        },
    },
];

const GetMyCampaignRequestsQuery = graphql(`
    query GetMyCampaignRequests {
        me {
            requestedCampaigns {
                status
                trackingLink
                currentCommission {
                    ...CommissionFragment
                }

                campaign {
                    id
                    title
                    contactEmail
                    commissionInfo {
                        currentCommission {
                            ...CommissionFragment
                        }
                    }

                    company {
                        imageUrl
                        name
                    }
                }
            }
        }
    }
`);

const CampaignsOverview: React.FC = () => {
    const [rows, setRows] = React.useState<CampaignRow[]>([]);

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const commissionFormatter = useCommissionFormatter();

    const [campaignRequestsRes] = useQuery({
        query: GetMyCampaignRequestsQuery,
    });

    const userCampaignStats = useUsersCampaignData({
        timeframe: Timeframe.Year,
    });

    React.useEffect(() => {
        const stats = userCampaignStats.data?.statistics;

        if (!campaignRequestsRes.data || !stats) {
            return;
        }

        const rows = campaignRequestsRes.data.me.requestedCampaigns.map(cr => {
            const totalStatsForCampaign = calcTotalStatsForCampaign(
                cr.campaign.id,
                stats,
            );

            return {
                id: cr.campaign.id,
                campaignRequest: cr,
                stats: totalStatsForCampaign,
            };
        });

        setRows(rows);
    }, [
        campaignRequestsRes.data,
        userCampaignStats.data?.statistics,
        commissionFormatter,
    ]);

    const ROW_HEIGHT = 52;
    const HEADER_FOOTER_HEIGHT = 56 + 65 + 2;

    const loading = campaignRequestsRes.fetching || userCampaignStats.fetching;

    return isSmallScreen ? (
        <MobileCampaignsOverview rows={rows} loading={loading} />
    ) : (
        <Card
            style={{
                height: !loading
                    ? HEADER_FOOTER_HEIGHT + rows.length * ROW_HEIGHT
                    : 200,
                width: "100%",
            }}
        >
            <DataGridPro
                loading={loading}
                rows={rows}
                columns={columns}
                disableRowSelectionOnClick
            />
        </Card>
    );
};

const calcTotalStatsForCampaign = (
    campaignId: string,
    stats: NonNullable<
        ReturnType<typeof useUsersCampaignData>["data"]
    >["statistics"],
) => {
    const emptyStats = {
        earnings: { currencies: [] },
        clicks: 0,
        conversions: 0,
        conversionRate: 0,
    };

    const getForCampaign = <T extends { campaignId: string }>(
        items: T[] | undefined,
    ) => items?.find(cp => cp.campaignId === campaignId) ?? emptyStats;

    const manual = getForCampaign(stats?.campaignPerformanceManual.byCampaign);
    const tune = getForCampaign(stats?.campaignPerformanceTune.byCampaign);

    const clicks = manual.clicks + tune.clicks;
    const conversions = manual.conversions + tune.conversions;
    const conversionRate = clicks === 0 ? 0 : conversions / clicks;
    const earnings = combineMultiCurrencies(manual.earnings, tune.earnings);

    return {
        earnings,
        clicks,
        conversions,
        conversionRate,
    };
};

export default CampaignsOverview;
