// next
import NextLink from "next/link";
// @mui
import { Box, Link, ListItemText } from "@mui/material";
// type
import { NavItemProps } from "../type";
//
import ChevronRightIcon from "@mui/icons-material/ChevronRightRounded";
import ExpandMoreIcon from "@mui/icons-material/ExpandMoreRounded";
import { ListItemStyle, ListItemTextStyle, ListItemIconStyle } from "./style";
import { isExternalLink } from "..";
import { ICON } from "../config";

// ----------------------------------------------------------------------

export function NavItemRoot({
    item,
    isCollapse,
    open = false,
    active,
    onOpen,
}: NavItemProps) {
    const { title, path, icon, info, children } = item;

    const renderContent = (
        <>
            {icon && (
                <ListItemIconStyle sx={{ minWidth: "initial", mr: 1 }}>
                    {icon}
                </ListItemIconStyle>
            )}
            <ListItemTextStyle
                disableTypography
                primary={title}
                isCollapse={isCollapse}
            />
            {!isCollapse && (
                <>
                    {info && info}
                    {children && <ArrowIcon open={open} />}
                </>
            )}
        </>
    );

    if (children) {
        return (
            <ListItemStyle onClick={onOpen} activeRoot={active}>
                {renderContent}
            </ListItemStyle>
        );
    }

    return isExternalLink(path) ? (
        <Link href={path} target="_blank" rel="noopener">
            <ListItemStyle>{renderContent}</ListItemStyle>
        </Link>
    ) : (
        <NextLink href={path} passHref legacyBehavior>
            <ListItemStyle activeRoot={active}>{renderContent}</ListItemStyle>
        </NextLink>
    );
}

// ----------------------------------------------------------------------

type NavItemSubProps = Omit<NavItemProps, "isCollapse">;

export function NavItemSub({
    item,
    open = false,
    active = false,
    onOpen,
}: NavItemSubProps) {
    const { title, path, info, children } = item;

    const renderContent = (
        <>
            <DotIcon active={active} />
            <ListItemText disableTypography primary={title} />
            {info && info}
            {children && <ArrowIcon open={open} />}
        </>
    );

    if (children) {
        return (
            <ListItemStyle onClick={onOpen} activeSub={active} subItem>
                {renderContent}
            </ListItemStyle>
        );
    }

    return isExternalLink(path) ? (
        <ListItemStyle
            component={Link}
            href={path}
            target="_blank"
            rel="noopener"
            subItem
        >
            {renderContent}
        </ListItemStyle>
    ) : (
        <NextLink href={path} passHref legacyBehavior>
            <ListItemStyle activeSub={active} subItem>
                {renderContent}
            </ListItemStyle>
        </NextLink>
    );
}

// ----------------------------------------------------------------------

type DotIconProps = {
    active: boolean;
};

export function DotIcon({ active }: DotIconProps) {
    return (
        <ListItemIconStyle sx={{ minWidth: "initial" }}>
            <Box
                component="span"
                sx={{
                    width: 4,
                    height: 4,
                    borderRadius: "50%",
                    bgcolor: "text.disabled",
                    transition: theme =>
                        theme.transitions.create("transform", {
                            duration: theme.transitions.duration.shorter,
                        }),
                    ...(active && {
                        transform: "scale(2)",
                        bgcolor: "primary.main",
                    }),
                }}
            />
        </ListItemIconStyle>
    );
}

// ----------------------------------------------------------------------

type ArrowIconProps = {
    open: boolean;
};

export function ArrowIcon({ open }: ArrowIconProps) {
    const size = 0.9 * ICON.NAVBAR_ITEM;
    const props = { sx: { width: size, height: size } };
    return open ? (
        <ExpandMoreIcon {...props} />
    ) : (
        <ChevronRightIcon {...props} />
    );
}
