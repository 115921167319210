import ReactPortal from "@components/utils/ReactPortal";
import { Alert, AlertColor, Snackbar } from "@mui/material";
import React, { useContext } from "react";

interface SnackbarContextProps {
    showSnackbar: (p: SnackbarProps) => void;
}

const SnackbarContext = React.createContext<SnackbarContextProps>({
    showSnackbar: () => ({}),
});

interface SnackbarProps {
    content: React.ReactNode;
    color?: AlertColor;
    timeout?: number;
    closeable?: boolean;
}

const useSnackbar = () => {
    const { showSnackbar } = useContext(SnackbarContext);

    return ({
        content,
        color = "success",
        timeout = 2000,
        closeable = false,
    }: SnackbarProps) => {
        showSnackbar({ content, color, timeout, closeable });
    };
};

interface ProviderProps {
    children: React.ReactNode;
}

export const SnackbarContextProvider: React.FC<ProviderProps> = ({
    children,
}) => {
    const [snackBars, setSnackBars] = React.useState<
        Array<SnackbarProps & { id: number }>
    >([]);
    const [id, setId] = React.useState(0);
    const [shouldClose, setShouldClose] = React.useState<number[]>([]);

    const showSnackbar = React.useCallback(
        (props: SnackbarProps) => {
            setSnackBars(x => [...x, { ...props, id }]);
            setId(x => x + 1);
        },
        [id],
    );

    const handleRemovalFromState = React.useCallback((id: number) => {
        setSnackBars(xs => xs.filter(x => x.id !== id));
    }, []);

    const handleCloseRequest = React.useCallback((id: number) => {
        setShouldClose(xs => [...xs, id]);
    }, []);

    return (
        <SnackbarContext.Provider value={{ showSnackbar }}>
            {children}
            <ReactPortal wrapperId="snack-wrapper">
                {snackBars.map(sb => (
                    <React.Fragment key={sb.id}>
                        <Snackbar
                            open={!shouldClose.some(id => id === sb.id)}
                            onClose={() => handleCloseRequest(sb.id)}
                            autoHideDuration={sb.timeout || 2000}
                            TransitionProps={{
                                onExited: () => handleRemovalFromState(sb.id),
                            }}
                        >
                            <Alert
                                severity={sb.color}
                                onClose={
                                    sb.closeable
                                        ? () => handleCloseRequest(sb.id)
                                        : undefined
                                }
                            >
                                {sb.content}
                            </Alert>
                        </Snackbar>
                    </React.Fragment>
                ))}
            </ReactPortal>
        </SnackbarContext.Provider>
    );
};

export default useSnackbar;
