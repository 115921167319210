import { Box, CircularProgress } from "@mui/material";

const LoadingPage: React.FC = () => (
    <Box
        sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
        }}
    >
        <CircularProgress />
    </Box>
);

export default LoadingPage;
