import { DocumentReference } from "firebase/firestore";
import { SupportedCurrency } from "./currency";
import { Social } from "./social";
import { Address } from "./user";
import { EarnGiftCardForCampaign } from "@lib/gql/graphql";

export interface CampaignWithoutCompanyId {
    id: string;
    requestStatus?: CampaignActivityStatus;
    countries: Array<Country>;
    coverImageUrl?: string;
    description: string;
    conversionType: string;
    media: Array<{ media: string; body: string }>;
    title: string;
    learnMoreUrl?: string;
    additionalInfo?: string;
    offerId?: string;
    cpa: Cpa;
    gifts?: Gift[];
    aboutCompany: string | undefined;
    conversionHandler?: ConversionHandler;
    addressRequired?: boolean;
    talkingPoints?: string;
    getStarted?: string;
    contactEmail?: string | null;
    earnGiftCard: EarnGiftCardForCampaign | null;
    tuneTermsAndConditions?: string | null;
}
export interface Campaign extends CampaignWithoutCompanyId {
    companyId: DocumentReference;
}

export interface CampaignWithCompanyInfo extends Campaign {
    aboutCompany: string;
    companyLogo: string;
    companyName: string;
}

export interface CampaignCompanyIdAsString extends CampaignWithoutCompanyId {
    companyId: string;
}

export interface CampaignWithCompanyIdAsString
    extends CampaignCompanyIdAsString {
    aboutCompany: string;
    companyLogo: string;
    companyName: string;
}

export interface CampaignWithCompanyInfoForServerSide
    extends CampaignWithoutCompanyId {
    companyId: string;
    aboutCompany: string;
    companyLogo: string;
    companyName: string;
}

export enum CampaignActivityStatus {
    Active = "Active",
    Stopped = "Stopped",
}

export enum CampaignStatus {
    Active = "Active",
    Pending = "Pending",
    Stopped = "Stopped",
    Denied = "Denied",
    Cancelled = "Cancelled",
}

/**
 * The `code` field should use the country's standardised ISO 3166 country code;
 * [see Wikipedia](https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes).
 * This allows us to easily use external sites for country flag images or
 * convert a country code into an emoji character.
 */
export type Country = {
    code: string;
    name: string;
};

export type UserCampaignData = {
    referralLink?: string;
    giftCode?: string;
    statistics: CampaignStatistics;
};

export type CampaignStatistics = {
    total: CampaignStatisticsDataPoint;
    pastWeekDailyDataPoints: CampaignStatisticsDataPoint[];
    pastMonthDailyDataPoints: CampaignStatisticsDataPoint[];
    pastYearMonthlyDataPoints: CampaignStatisticsDataPoint[];
};

export type CampaignStatisticsDataPoint = {
    dateString: string;
    clicks: number;
    conversions: number;
    conversionRate: number;
    earnings: Money;
};

export interface PersonalCampaignValues {
    giftCode?: string;
    cpa?: Cpa | null;
    commissionHistory?: CommissionHistory;
    socials?: Social[];
    /**
     * A custom affiliate link to be used only when TUNE won't be able to
     * autogenerate one.
     */
    affiliateLink?: string;
    affiliateCode?: string;
    gift?: Gift;
}

export enum ConversionHandler {
    manual = "Manual",
    tune = "TUNE",
}

export type RequestedCampaign = PersonalCampaignValues & {
    campaignId: DocumentReference;
    socials: Social[];
    status: CampaignStatus;
    manualStats?: Record<
        string,
        { [key: string]: { conversions: number; clicks: number } | boolean }
    >;
    conversionHandler: ConversionHandler;
    giftCode?: string;
    giftCardHasBeenUsed?: boolean;
    /**
     * The timestamp at which the user requested the campaign
     */
    requestTimestamp?: string;
    /**
     * The timestamp at which the campaign was stopped
     */
    stoppedTimestamp?: string;
    /**
     * The timestamp at which the campaign was accepted
     */
    acceptedTimestamp?: string;
    shippingAddress?: Address;
};

export interface RequestedCampaignWithCampaignAndCompanyInfo
    extends RequestedCampaign {
    campaign: CampaignWithCompanyInfo;
}

export interface Money {
    amount: number;
    currency: SupportedCurrency;
}

export interface Percentage {
    percentage: number;
}

export type Cpa = Money | Percentage;

export interface Gift {
    name: string;
    tuneUrlId: string;
}

export enum CommissionType {
    FixedAmount = "Fixed Amount",
    RevenueShare = "Revenue Share",
}

export interface PrivateCampaignInfo {
    invoiceCpa: Cpa;
    stripeActivationTimestamp: Date;
    stripeCustomerId: string;
    lastInvoiceRunTimestamp?: string;
    invoiceCurrency?: string;
}

// All PREVIOUS commissions (i.e. not including their current commission)
export type CommissionHistory = CommissionHistoryItem[];

export type CommissionHistoryItem = {
    commission: Cpa | null;
    startTimestamp: string;
    endTimestamp: string;
};
