import NavbarSidebarLayout from "@components/layout";
import UserDashboard from "@components/user/dashboard/dashboard";
import LoadingPage from "@components/utils/LoadingPage";
import useHasRole from "@hooks/roles/useHasRole";
import { Role } from "@lib/types/auth";
import { NextPageWithLayout } from "@lib/types/next";
import { Container } from "@mui/material";
import Head from "next/head";
import { useRouter } from "next/router";
import React from "react";

const Home: NextPageWithLayout = () => {
    const [isCompany, loadingRoles] = useHasRole([
        Role.CompanyCampaignManager,
        Role.CompanyOwner,
    ]);
    const router = useRouter();

    React.useEffect(() => {
        if (!loadingRoles && isCompany && router)
            router.replace("/company/campaigns");
    }, [isCompany, loadingRoles, router]);

    return (
        <>
            <Head>
                <title>Filify</title>
            </Head>

            {loadingRoles ? <LoadingPage /> : <UserDashboard />}
        </>
    );
};

Home.getLayout = page => {
    return (
        <NavbarSidebarLayout>
            <Container>{page}</Container>
        </NavbarSidebarLayout>
    );
};

export default Home;
