import React from "react";
import NextLink from "next/link";
import { useRouter } from "next/router";
import { Box, Button, IconButton, Stack } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { drawerBreakpoint } from "@components/layout";
import { auth } from "@lib/firebase-internal/clientApp";
import { useAuthState } from "react-firebase-hooks/auth";
import { UserAvatarWithMenu } from "@components/navbar/userAvatar";
import { loginUrlWithRedirect, signupUrlWithRedirect } from "@utils/login";

const NavbarContent: React.FC<{
    onDrawerToggle: () => void;
    extraContent?: React.ReactNode[];
}> = ({ onDrawerToggle, extraContent }) => {
    const [user] = useAuthState(auth);

    const { asPath } = useRouter();
    const loginUrl = loginUrlWithRedirect(asPath);
    const signupUrl = signupUrlWithRedirect(asPath);

    // You get a hydration error if you don't encapsulate the login button vs.
    // avatar render in a hook...
    const [profileButton, setProfileButton] = React.useState<
        React.ReactNode | undefined
    >(undefined);

    React.useEffect(() => {
        setProfileButton(
            user ? (
                <UserAvatarWithMenu />
            ) : (
                <>
                    <NextLink href={loginUrl} passHref legacyBehavior>
                        <Button size="medium">Login</Button>
                    </NextLink>
                    <NextLink href={signupUrl} passHref legacyBehavior>
                        <Button size="medium" variant="contained">
                            Sign up
                        </Button>
                    </NextLink>
                </>
            ),
        );
    }, [user, loginUrl, signupUrl]);

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
            }}
        >
            <Stack direction="row" spacing={2}>
                <IconButton
                    aria-label="open drawer"
                    edge="start"
                    onClick={onDrawerToggle}
                    sx={{
                        mr: 2,
                        display: { [drawerBreakpoint]: "none" },
                    }}
                >
                    <MenuIcon />
                </IconButton>
            </Stack>
            <Stack direction="row" spacing={3} sx={{ alignItems: "center" }}>
                {extraContent}
                {profileButton}
            </Stack>
        </Box>
    );
};

export default NavbarContent;
