/* eslint-disable */
import { ReactElement, forwardRef } from "react";
// next
import NextLink from "next/link";
// @mui
import { Box, Link } from "@mui/material";
// type
import { NavItemProps } from "../type";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ListItemStyle } from "./style";
import { isExternalLink } from "..";
import { ICON } from "../config";

// ----------------------------------------------------------------------

export const NavItemRoot = forwardRef<any, NavItemProps>(
    ({ item, active, open, onMouseEnter, onMouseLeave }, ref) => {
        const { title, path, icon, children } = item;

        if (children) {
            return (
                <ListItemStyle
                    ref={ref}
                    open={open}
                    activeRoot={active}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                >
                    <NavItemContent
                        icon={icon}
                        title={title}
                        children={children}
                    />
                </ListItemStyle>
            );
        }

        return isExternalLink(path) ? (
            <ListItemStyle
                component={Link}
                href={path}
                target="_blank"
                rel="noopener"
            >
                <NavItemContent icon={icon} title={title} children={children} />
            </ListItemStyle>
        ) : (
            <NextLink href={path} legacyBehavior>
                <ListItemStyle activeRoot={active}>
                    <NavItemContent
                        icon={icon}
                        title={title}
                        children={children}
                    />
                </ListItemStyle>
            </NextLink>
        );
    },
);

// ----------------------------------------------------------------------

export const NavItemSub = forwardRef<any, NavItemProps>(
    ({ item, active, open, onMouseEnter, onMouseLeave }, ref) => {
        const { title, path, icon, children } = item;

        if (children) {
            return (
                <ListItemStyle
                    ref={ref}
                    subItem
                    disableRipple
                    open={open}
                    activeSub={active}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                >
                    <NavItemContent
                        icon={icon}
                        title={title}
                        children={children}
                        subItem
                    />
                </ListItemStyle>
            );
        }

        return isExternalLink(path) ? (
            <ListItemStyle
                subItem
                href={path}
                disableRipple
                rel="noopener"
                target="_blank"
                component={Link}
            >
                <NavItemContent
                    icon={icon}
                    title={title}
                    children={children}
                    subItem
                />
            </ListItemStyle>
        ) : (
            <NextLink href={path} legacyBehavior>
                <ListItemStyle disableRipple activeSub={active} subItem>
                    <NavItemContent
                        icon={icon}
                        title={title}
                        children={children}
                        subItem
                    />
                </ListItemStyle>
            </NextLink>
        );
    },
);

// ----------------------------------------------------------------------

type NavItemContentProps = {
    title: string;
    icon?: ReactElement;
    children?: { title: string; path: string }[];
    subItem?: boolean;
};

function NavItemContent({
    icon,
    title,
    children,
    subItem,
}: NavItemContentProps) {
    return (
        <>
            {icon && (
                <Box
                    component="span"
                    sx={{
                        mr: 1,
                        width: ICON.NAVBAR_ITEM_HORIZONTAL,
                        height: ICON.NAVBAR_ITEM_HORIZONTAL,
                        "& svg": { width: "100%", height: "100%" },
                    }}
                >
                    {icon}
                </Box>
            )}
            {title}
            {children && (subItem ? <ExpandMoreIcon /> : <ChevronRightIcon />)}
        </>
    );
}
