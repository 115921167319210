import React from "react";
import ReactApexChart, { BaseOptionChart } from "@components/mui/chart";
import mergeDeep, { RecursiveObject } from "@utils/object";
import { ApexOptions } from "apexcharts";
import {
    Box,
    Chip,
    FormControl,
    MenuItem,
    Select,
    Stack,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";
import { combineMultiCurrencies, moneyToString } from "@utils/currency";
import { addDays, addMonths, format, startOfDay, startOfMonth } from "date-fns";
import { InfluencerPerformanceData } from "@hooks/useUsersCampaignData";
import { MultiCurrencyMoney } from "@lib/gql/graphql";
import { SupportedCurrency } from "@lib/types/currency";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export enum View {
    Clicks = "Clicks",
    Conversions = "Conversions",
    Earnings = "Earnings",
}

export enum Timeframe {
    Week = "Week",
    Month = "Month",
    Year = "Year",
}

type ChartData = {
    view: View;
    series: ApexAxisChartSeries;
    xLabels: string[];
    yFormatter: (val: number) => string;
    title: string;
    subtitle: string;
    hasAnyNonZeroValues: boolean;
};

const CHART_HEIGHT = 320;

interface Props {
    userCampaignData: InfluencerPerformanceData | undefined;
    timeframe: Timeframe;
    onTimeframeChange: (timeframe: Timeframe) => void;
    fallbackCurrency?: SupportedCurrency;
}

const useBaseOptions = () => {
    const theme = useTheme();
    const [baseOptions, setBaseOptions] = React.useState<ApexOptions>();

    React.useEffect(() => {
        setBaseOptions({ ...BaseOptionChart(theme) });
    }, [theme]);

    return baseOptions;
};

const CampaignsPerformanceChart: React.FC<Props> = ({
    userCampaignData,
    timeframe,
    onTimeframeChange,
    fallbackCurrency,
}) => {
    const theme = useTheme();
    const baseOptions = useBaseOptions();
    const [view, setView] = React.useState(View.Earnings);
    const [chartData, setChartData] = React.useState<ChartData>(EMPTY_CHART);
    const [chartOptions, setChartOptions] = React.useState<ApexOptions>();

    const [currencyMessage, setCurrencyMessage] = React.useState<string | null>(
        null,
    );

    React.useEffect(() => {
        if (!userCampaignData?.statistics) return;

        const currenciesByFrequency =
            orderCurrenciesByCampaignFrequency(userCampaignData);

        const currencyToUse =
            currenciesByFrequency.length > 0
                ? (currenciesByFrequency[0] as SupportedCurrency)
                : fallbackCurrency;

        setCurrencyMessage(
            currenciesByFrequency.length > 1
                ? `You have earnings in multiple currencies. The chart only shows your most common currency, ${currencyToUse}.`
                : null,
        );

        setChartData(
            convertCampaignStatisticsToChartData(
                userCampaignData,
                view,
                timeframe,
                currencyToUse,
            ),
        );
    }, [view, timeframe, userCampaignData, fallbackCurrency]);

    React.useEffect(() => {
        setChartOptions(
            generateChartOptions(
                // To make apexcharts update the yFormatter, we need to pass some
                // reactive state... Pretty cursed, but it works. 3 year old
                // issue here: https://github.com/apexcharts/react-apexcharts/issues/107
                {
                    ...baseOptions,
                    unusedKey: chartData,
                } as ApexOptions,
                chartData,
                theme.breakpoints.values.md,
            ),
        );
    }, [baseOptions, chartData, theme.breakpoints.values.md]);

    const createViewChip = React.useCallback(
        (viewToShow: View) => {
            return (
                <Chip
                    label={viewToShow}
                    onClick={() => setView(viewToShow)}
                    color={viewToShow === view ? "primary" : undefined}
                />
            );
        },
        [view, setView],
    );

    return (
        <>
            <Box sx={{ p: 2 }}>
                <Stack direction="row" justifyContent="space-between">
                    <Box>
                        <Stack direction="row" spacing={2} alignItems="center">
                            <Typography variant="h3">
                                {chartData.title}
                            </Typography>
                            {currencyMessage && view === View.Earnings ? (
                                <Tooltip
                                    title={currencyMessage}
                                    enterTouchDelay={0}
                                >
                                    <InfoOutlinedIcon color="primary" />
                                </Tooltip>
                            ) : undefined}
                        </Stack>
                        <Typography variant="body2">
                            {chartData.subtitle}
                        </Typography>
                    </Box>
                    <FormControl size="small">
                        <Select
                            value={timeframe}
                            onChange={e =>
                                onTimeframeChange(e.target.value as Timeframe)
                            }
                        >
                            {Object.keys(Timeframe).map(key => (
                                <MenuItem key={key} value={key}>
                                    {Timeframe[key as Timeframe]}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Stack>
                <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="flex-end"
                    pt={1}
                >
                    {createViewChip(View.Earnings)}
                    {createViewChip(View.Conversions)}
                    {createViewChip(View.Clicks)}
                </Stack>
            </Box>
            {!!chartOptions && (
                <ReactApexChart
                    type="bar"
                    series={chartData.series}
                    options={chartOptions}
                    height={CHART_HEIGHT}
                />
            )}
        </>
    );
};

const generateChartOptions = (
    baseOptions: ApexOptions,
    chartData: ChartData,
    breakpoint?: number,
) => {
    const options = mergeDeep(baseOptions as RecursiveObject<ApexOptions>, {
        plotOptions: { bar: { columnWidth: "16%" } },
        stroke: { show: false },
        xaxis: {
            categories: chartData.xLabels,
        },
        yaxis: {
            labels: {
                formatter: (val: number) => val.toFixed(0),
            },
            max: chartData.hasAnyNonZeroValues ? undefined : 100,
        },
        tooltip: {
            y: {
                formatter: chartData.yFormatter,
            },
        },
        responsive: [
            {
                breakpoint,
                options: {
                    xaxis: {
                        axisTicks: false,
                        labels: {
                            rotate: -15,
                        },
                    },
                },
            },
        ],
    });

    return options;
};

interface ChartDataOption {
    extractValue: (dataPoint: ChartDatapoint) => number;
    yFormatter: (val: number) => string;
    optionName: string;
}

const clicksChartOption: ChartDataOption = {
    extractValue: dataPoint => dataPoint.clicks,
    yFormatter: (val: number) => `${val}`,
    optionName: View.Clicks as string,
};

const conversionsChartOption: ChartDataOption = {
    extractValue: dataPoint => dataPoint.conversions,
    yFormatter: (val: number) => `${val}`,
    optionName: View.Conversions as string,
};

const makeEarningsChartOption = (
    currencyToUse: SupportedCurrency | undefined,
): ChartDataOption => {
    return {
        optionName: View.Earnings as string,
        yFormatter: (val: number) =>
            currencyToUse
                ? moneyToString({ amount: val, currency: currencyToUse })
                : val.toString(),
        extractValue: dataPoint =>
            dataPoint.earnings.currencies.find(
                c => c.currency === currencyToUse,
            )?.amount || 0,
    };
};

const EMPTY_CHART: ChartData = {
    view: View.Earnings,
    series: [
        {
            name: View.Earnings as string,
            data: [],
        },
    ],
    xLabels: [],
    yFormatter: () => "",
    title: "",
    subtitle: "",
    hasAnyNonZeroValues: false,
};

const convertCampaignStatisticsToChartData = (
    influencerData: InfluencerPerformanceData,
    view: View,
    timeframe: Timeframe,
    currencyToUse: SupportedCurrency | undefined,
): ChartData => {
    const chartOption = {
        [View.Clicks]: clicksChartOption,
        [View.Conversions]: conversionsChartOption,
        [View.Earnings]: makeEarningsChartOption(currencyToUse),
    }[view];

    const xLabelFormatter = {
        [Timeframe.Year]: (date: Date) => format(date, "MMM"),
        [Timeframe.Month]: (date: Date) => format(date, "MMM dd"),
        [Timeframe.Week]: (date: Date) => format(date, "EEE"),
    }[timeframe];

    const datapoints = mergeDatapointsByTimeframe(influencerData, timeframe);

    const xLabels = datapoints.map(datapoint =>
        xLabelFormatter(datapoint.date),
    );

    const { extractValue, yFormatter } = chartOption;

    return {
        view,
        series: [
            {
                name: view as string,
                data: datapoints.map(extractValue),
            },
        ],
        title: yFormatter(
            datapoints.reduce((acc, dp) => acc + extractValue(dp), 0),
        ),
        subtitle: `${
            chartOption.optionName
        } in the last ${timeframe.toLowerCase()}`,
        xLabels,
        yFormatter,
        hasAnyNonZeroValues: datapoints.some(dp => extractValue(dp) > 0),
    };
};

type ChartDatapoint = {
    date: Date;
    clicks: number;
    conversions: number;
    earnings: MultiCurrencyMoney;
};

const hashers = {
    [Timeframe.Week]: (d: Date) => startOfDay(d).getTime(),
    [Timeframe.Month]: (d: Date) => startOfDay(d).getTime(),
    [Timeframe.Year]: (d: Date) => startOfMonth(d).getTime(),
};

const incrementers = {
    [Timeframe.Week]: (d: Date) => addDays(d, 1),
    [Timeframe.Month]: (d: Date) => addDays(d, 1),
    [Timeframe.Year]: (d: Date) => addMonths(d, 1),
};

const mergeDatapointsByTimeframe = (
    influencerData: InfluencerPerformanceData,
    timeframe: Timeframe,
): ChartDatapoint[] => {
    const buckets: Record<number, ChartDatapoint> = {};
    const hasher = hashers[timeframe];
    const increment = incrementers[timeframe];

    let d = influencerData.dateRange.fromTimestamp;
    while (d <= influencerData.dateRange.toTimestamp) {
        const bucket = hasher(d);
        buckets[bucket] = {
            date: d,
            clicks: 0,
            conversions: 0,
            earnings: { currencies: [] },
        };
        d = increment(d);
    }

    const datapoints = [
        ...influencerData.statistics.campaignPerformanceTune.byDate,
        ...influencerData.statistics.campaignPerformanceManual.byDate,
    ];

    datapoints.forEach(({ date, clicks, conversions, earnings }) => {
        const bucket = hasher(new Date(date));
        if (!Object.hasOwn(buckets, bucket)) return;

        buckets[bucket].clicks += clicks;
        buckets[bucket].conversions += conversions;
        buckets[bucket].earnings = combineMultiCurrencies(
            buckets[bucket].earnings,
            earnings,
        );
    });

    return Object.values(buckets).sort(
        (a, b) => a.date.getTime() - b.date.getTime(),
    );
};

const orderCurrenciesByCampaignFrequency = (
    userCampaignData: InfluencerPerformanceData,
) => {
    const datapoints = [
        ...userCampaignData.statistics.campaignPerformanceTune.byCampaign,
        ...userCampaignData.statistics.campaignPerformanceManual.byCampaign,
    ];

    const campaignCurrencies = datapoints.reduce(
        (acc, { campaignId, earnings }) => {
            if (!Object.hasOwn(acc, campaignId)) acc[campaignId] = new Set();
            earnings.currencies.forEach(c => acc[campaignId].add(c.currency));
            return acc;
        },
        {} as Record<string, Set<string>>,
    );

    const currencyCounts = Object.values(campaignCurrencies).reduce(
        (acc, currencies) => {
            currencies.forEach(c => {
                if (!Object.hasOwn(acc, c)) acc[c] = 0;
                acc[c]++;
            });
            return acc;
        },
        {} as Record<string, number>,
    );

    const currencies = Object.keys(currencyCounts).sort((a, b) => {
        const diff = currencyCounts[b] - currencyCounts[a];

        // In case of a tie, we prefer EUR
        if (diff === 0 && a === SupportedCurrency.EUR) return -1;
        if (diff === 0 && b === SupportedCurrency.EUR) return 1;
        return diff;
    });

    return currencies;
};

export default CampaignsPerformanceChart;
