export const API_HOST_URL: string = (() => {
    if (!process.env.NEXT_PUBLIC_FUNCTIONS_ENDPOINT) {
        // eslint-disable-next-line no-console
        console.warn("The API host URL variable was not found");
    }

    return process.env.NEXT_PUBLIC_FUNCTIONS_ENDPOINT ?? "";
})();

export const FILIFY_CAMPAIGN_TRANSACTION_ID_KEY =
    "filifyCampaign_transactionId" as const;
export const FILIFY_CAMPAIGN_OFFER_ID_KEY = "filifyCampaign_offerId" as const;
export const FILIFY_CAMPAIGN_AFFILIATE_ID_KEY =
    "filifyCampaign_affiliateId" as const;
