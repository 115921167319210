// import { matchPath } from 'react-router-dom';

// ----------------------------------------------------------------------

export { default as NavSectionVertical } from "./vertical";
export { default as NavSectionHorizontal } from "./horizontal";

export function isExternalLink(path: string) {
    return path.includes("http");
}

export function getActive(path: string, pathname: string, asPath: string) {
    // The "is active page" detection has been modified by us. Instead of having
    // the nav item URL be a substring of the current url, it must match exactly
    return pathname === path || asPath.split("?")[0] === path;
}
