import React from "react";
import SidebarContent from "@components/sidebarContent";
import NavbarContent from "@components/navbarContent";
import {
    Box,
    Drawer,
    SxProps,
    Theme,
    Toolbar,
    useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

const drawerWidth = 240;
export const drawerBreakpoint = "md";

const NavbarSidebarLayout: React.FC<
    React.PropsWithChildren<{
        sx?: SxProps<Theme>;
        navbarContent?: React.ReactNode[];
    }>
> = ({ children, sx, ...props }) => {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const theme = useTheme();
    const sxProps = sx ?? {};
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

    const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

    return (
        <Box
            sx={{
                display: "flex",
                height: "100vh",
                backgroundColor: theme.palette.background.paper,
            }}
        >
            <Box
                component="nav"
                sx={{
                    width: { [drawerBreakpoint]: drawerWidth },
                    flexShrink: { [drawerBreakpoint]: 0 },
                }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: "block", [drawerBreakpoint]: "none" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                        },
                    }}
                >
                    <SidebarContent />
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: "none", [drawerBreakpoint]: "block" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                        },
                    }}
                    open
                >
                    <SidebarContent />
                </Drawer>
            </Box>

            <Box
                sx={{
                    flexGrow: 1,
                    width: {
                        [drawerBreakpoint]: `calc(100% - ${drawerWidth}px)`,
                    },
                }}
            >
                <Toolbar>
                    <NavbarContent
                        onDrawerToggle={handleDrawerToggle}
                        extraContent={props.navbarContent}
                    />
                </Toolbar>
                <Box
                    component="main"
                    sx={{ p: isSmallScreen ? 0 : 3, ...sxProps }}
                >
                    {children}
                </Box>
            </Box>
        </Box>
    );
};

export default NavbarSidebarLayout;
