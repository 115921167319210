export const hasValue = <T>(elem: T | undefined | null): elem is T =>
    elem !== undefined && elem !== null;

export type ArrayElementType<T> = T extends (infer U)[] ? U : never;

export const invertObject = <
    K extends string | number | symbol,
    V extends string | number | symbol,
>(
    obj: Record<K, V>,
): Record<V, K> =>
    Object.fromEntries(Object.entries(obj).map(([k, v]) => [v, k]));

/**
 * A utility type that makes all the fields in an object visible in the editor when a type is a union of types.
 *
 * */
export type ShowAllFields<T> = {
    [K in keyof T]: T[K];
    // eslint-disable-next-line @typescript-eslint/ban-types
} & {};

export const sanitizeFloatingPoint = (num: number) => {
    const precision = 14; // precision level to fix floating-point errors
    return parseFloat(num.toPrecision(precision));
};

/**
 * A utility type that requires all the fields in an object to be present.
 * That is, it converts all `field?: V` to `field: V | undefined`.
 */
export type Complete<T> = {
    [P in keyof Required<T>]: Pick<T, P> extends Required<Pick<T, P>>
        ? T[P]
        : T[P] | undefined;
};
