export const pageview = (path: string, title?: string) => {
    window.gtag("event", "page_view", {
        page_path: path,
        page_title: title,
    });
};

export const setUser = (userId: string) => {
    window.gtag("set", {
        user_id: userId,
    });
};
