import React from "react";
import { Button, Card, Link, Stack, Typography } from "@mui/material";
import CampaignsOverview from "./campaignsOverview";
import CampaignsPerformanceChart, {
    Timeframe,
} from "./campaignsPerformanceChart";
import useRedirectNoAuthToLogin from "@hooks/useRedirectToLogin";
import LoadingPage from "@components/utils/LoadingPage";
import { countUserCampaigns } from "@lib/firebase-internal/campaign";
import NextLink from "next/link";
import useUsersCampaignData from "@hooks/useUsersCampaignData";
import useSnackbar from "@hooks/useSnackbar";

const UserDashboard = () => {
    const [user, userIsLoading] = useRedirectNoAuthToLogin();
    const [hasAnyCampaigns, setHasAnyCampaigns] = React.useState(false);
    const [numCampaignsIsLoading, setNumCampaignsIsLoading] =
        React.useState(true);
    const showSnackbar = useSnackbar();

    const [timeframe, setTimeframe] = React.useState(Timeframe.Year);

    const userCampaignStatsRes = useUsersCampaignData({
        shouldWait: !hasAnyCampaigns,
        timeframe,
    });

    React.useEffect(() => {
        if (!user) {
            return;
        }

        countUserCampaigns(user.uid).then(count => {
            setHasAnyCampaigns(count > 0);
            setNumCampaignsIsLoading(false);
        });
    }, [user]);

    React.useEffect(() => {
        if (userCampaignStatsRes.error) {
            showSnackbar({
                content:
                    "Failed to load your statistics. Please reload and try again.",
                color: "error",
                timeout: 5000,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userCampaignStatsRes.error]);

    if (userIsLoading || numCampaignsIsLoading || !user) {
        return <LoadingPage />;
    }

    return (
        <Stack spacing={3}>
            <Typography variant="h2">Dashboard</Typography>
            {!hasAnyCampaigns ? (
                <GetStartedMessage />
            ) : (
                <>
                    <Card sx={{ p: 1 }}>
                        <CampaignsPerformanceChart
                            userCampaignData={userCampaignStatsRes.data}
                            timeframe={timeframe}
                            onTimeframeChange={setTimeframe}
                        />
                    </Card>
                    <CampaignsOverview />
                </>
            )}
        </Stack>
    );
};

const GetStartedMessage = () => {
    return (
        <Stack
            spacing={3}
            height="200px"
            justifyContent="center"
            alignItems="center"
        >
            <Typography variant="body1">
                It looks like you&apos;re not signed up for any campaigns yet.
            </Typography>
            <NextLink href="/discover" passHref legacyBehavior>
                <Button variant="contained" component={Link} size="large">
                    Get started!
                </Button>
            </NextLink>
        </Stack>
    );
};

export default UserDashboard;
