import { API_HOST_URL } from "@lib/constants";
import { db } from "@lib/firebase-internal/clientApp";
import {
    Campaign,
    CampaignStatus,
    CampaignWithCompanyInfo,
    CampaignWithCompanyInfoForServerSide,
    PersonalCampaignValues,
    UserCampaignData,
} from "@lib/types/campaign";
import { Company } from "@lib/types/company";
import { campaignStatusFromString } from "@utils/campaignStatus";
import { authenticatedFetcher } from "@utils/fetcher";
import { getKeys } from "@utils/object";
import {
    addDoc,
    collection,
    doc,
    getDoc,
    getDocs,
    setDoc,
} from "firebase/firestore";

export const getCampaignWithCompanyInfo = async (
    id: string,
): Promise<CampaignWithCompanyInfo | undefined> => {
    const docSnap = await getDoc(doc(db, "campaigns", id));
    const docData = docSnap.data() as Campaign | undefined;

    if (!docData) {
        return undefined;
    }

    const companySnap = await getDoc(docData.companyId);
    const companyData = companySnap.data() as Company | undefined;

    if (!companyData) {
        return undefined;
    }

    const toReturn: CampaignWithCompanyInfo = {
        ...(docData as Campaign),
        companyId: docData.companyId,
        companyName: companyData.name,
        aboutCompany: docData.aboutCompany ?? companyData.about ?? "",
        companyLogo: companyData.imageUrl,
    };

    return toReturn;
};

export const getCampaignWithCompanyInfoForServerSide = async (
    id: string,
): Promise<CampaignWithCompanyInfoForServerSide | undefined> => {
    const campaign = await getCampaignWithCompanyInfo(id);
    if (!campaign) {
        return undefined;
    }
    return { ...campaign, companyId: campaign.companyId.id };
};

export const createCampaign = async (campaign: Campaign): Promise<void> => {
    await addDoc(collection(db, "campaigns"), campaign);
};

export const getUserCampaignStatus = async (
    userId: string,
    campaignId: string,
): Promise<CampaignStatus | undefined> => {
    const userRef = doc(db, "users", userId);
    const campaignsRef = collection(userRef, "requestedCampaigns");
    const userCampaignSnap = await getDoc(doc(campaignsRef, campaignId));

    if (!userCampaignSnap.exists) {
        return undefined;
    }

    const campaignStatus = userCampaignSnap.data()?.status;
    return campaignStatus
        ? campaignStatusFromString(campaignStatus)
        : undefined;
};

export const getUserCampaignData = async (
    authToken: string,
    campaignId: string | null | undefined,
): Promise<UserCampaignData | undefined> => {
    const fetcher = authenticatedFetcher(authToken, "GET");
    const campaignSuffix = campaignId
        ? `/${encodeURIComponent(campaignId)}`
        : "";
    const url = `${API_HOST_URL}/userCampaignInfo${campaignSuffix}`;
    const res = await fetcher(url);
    return res.ok ? res.json() : undefined;
};

export const setUserCampaignStatus = async (
    userId: string,
    campaignId: string,
    campaignStatus: CampaignStatus,
    customValues: PersonalCampaignValues,
) => {
    const definedCustomValues = getKeys(customValues).reduce(
        (acc, key) =>
            customValues[key] ? { ...acc, [key]: customValues[key] } : acc,
        {},
    );

    const toReturn: { status: CampaignStatus; acceptedTimestamp?: string } = {
        status: campaignStatus,
        ...definedCustomValues,
    };

    if (campaignStatus === CampaignStatus.Active) {
        toReturn.acceptedTimestamp = new Date().toISOString();
    }

    const docRef = doc(db, "users", userId, "requestedCampaigns", campaignId);
    await setDoc(docRef, toReturn, { merge: true });
};

export const countUserCampaigns = async (userId: string) => {
    const userRef = doc(db, "users", userId);
    const requestedCampaignsRef = collection(userRef, "requestedCampaigns");
    const querySnapshot = await getDocs(requestedCampaignsRef);
    return querySnapshot.size;
};
