export const createCampaignLink = (campaignId: string) =>
    `/campaign/${encodeURIComponent(campaignId)}` as const;

export const createCampaignStatsLink = (campaignId: string) =>
    `/my-campaigns/${encodeURIComponent(campaignId)}` as const;

export const createCampaignFullLink = (campaignId: string) =>
    `${
        typeof window !== "undefined" ? window.location.origin : ""
    }${createCampaignLink(campaignId)}` as const;
