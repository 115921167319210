import { User } from "@firebase/auth";
import { auth } from "@lib/firebase-internal/clientApp";
import { loginUrlWithRedirect } from "@utils/login";
import { useRouter } from "next/router";
import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";

/**
 * A hook that redirects the user to login if no session is detected
 * @returns The result of the useAuthState hook
 */
const useRedirectNoAuthToLogin = (
    redirectToCurrentPath = true,
): [user: User | undefined, loadingAuth: boolean] => {
    const [user, userLoading] = useAuthState(auth);
    const { replace, asPath } = useRouter();

    React.useEffect(() => {
        if (!userLoading && (user === null || user === undefined)) {
            if (asPath === "/") {
                replace("/discover");
            } else {
                replace(
                    redirectToCurrentPath
                        ? loginUrlWithRedirect(asPath)
                        : "/login",
                );
            }
        }
    }, [userLoading, replace, user, asPath, redirectToCurrentPath]);
    return [user ?? undefined, userLoading];
};

export default useRedirectNoAuthToLogin;
