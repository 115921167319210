import ActionAlert from "@components/utils/actionAlert";
import { impersonationContext } from "@hooks/campaign-manager/useImpersonation";
import React from "react";

const ImpersonateAlert = () => {
    const { preImpersonationAuthToken, loginWithPreImpersonationToken } =
        React.useContext(impersonationContext);

    const [inBottom, setInBottom] = React.useState(true);

    const buttons = React.useMemo(() => {
        return [
            {
                label: `Move to ${inBottom ? "top" : "bottom"}`,
                onClick: () => setInBottom(!inBottom),
            },
            {
                label: "Return to your own account",
                onClick: () => loginWithPreImpersonationToken(),
            },
        ];
    }, [inBottom, setInBottom, loginWithPreImpersonationToken]);

    if (!preImpersonationAuthToken) {
        return null;
    }

    return (
        <ActionAlert
            text="You are currently impersonating another user! Your actions will count as if the user did them."
            buttons={buttons}
            sx={{
                position: "fixed",
                width: "calc(100% - 24px)",
                left: "50%",
                transform: "translateX(-50%)",
                bottom: inBottom ? 12 : undefined,
                top: inBottom ? undefined : 12,
                zIndex: 9999,
            }}
        />
    );
};

export default ImpersonateAlert;
