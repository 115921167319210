import { API_HOST_URL } from "@lib/constants";
import { HttpMethod } from "@lib/types/http";
import React from "react";

export const fetcher = (url: string) => fetch(url).then(res => res.json());

export const authenticatedFetcher = (
    authToken: string,
    method: HttpMethod,
    body?: Array<unknown> | Record<string, unknown>,
) => {
    return (url: string) =>
        fetch(url, {
            method,
            headers: {
                Authorization: authToken,
                "Content-Type": "application/json;charset=utf-8",
            },
            body: body ? JSON.stringify(body) : undefined,
        });
};

export const useAuthenticatedFetcher = () => {
    const customFetcher = React.useCallback(
        async <T = unknown>(
            url: string,
            token: string,
            method: HttpMethod = "GET",
            body?: Array<unknown> | Record<string, unknown>,
        ) => {
            if (!token) {
                throw new Error("Coulnd't get users access token");
            }

            return await fetch(`${API_HOST_URL}${url}`, {
                method,
                headers: {
                    Authorization: token,
                    "Content-Type": "application/json;charset=utf-8",
                },
                body: body ? JSON.stringify(body) : undefined,
            }).then<T | null>(res => {
                if (res.status === 204) {
                    return null;
                }

                return res.json();
            });
        },
        [],
    );

    return customFetcher;
};
