import { isRole, Role } from "@lib/types/auth";
import React from "react";
import useAuthRoles from "./useAuthRoles";

const useHasRole = (
    requiredRole: Role | Role[],
): [hasRole: boolean, loading: boolean] => {
    const roleInfo = useAuthRoles();

    const [loading, setLoading] = React.useState(true);
    const [hasRole, setHasRole] = React.useState(false);

    const requiredRoles = React.useMemo(
        () => (Array.isArray(requiredRole) ? requiredRole : [requiredRole]),
        [requiredRole],
    );

    React.useEffect(() => {
        if (!roleInfo) return;
        setHasRole(
            requiredRoles.some(
                requiredRole =>
                    roleInfo.findIndex(r => isRole(r, requiredRole)) !== -1,
            ),
        );
        setLoading(false);
    }, [roleInfo, requiredRoles]);

    return [hasRole, loading];
};

export default useHasRole;
