import React from "react";
import ConfirmationDialog, { DialogProps } from "./confirmationDialog";

interface ConfirmationDialogContext {
    show: (p: DialogProps) => Promise<"cancel" | "confirm">;
    active: boolean;
}

export const confirmationDialogContext =
    React.createContext<ConfirmationDialogContext>({
        active: false,
        show: () => Promise.resolve("cancel"),
    });

export const ConfirmationDialogContextProvider: React.FC<{
    children: JSX.Element;
}> = ({ children }) => {
    const [active, setActive] = React.useState(false);
    const [dialogProps, setDialogProps] = React.useState<DialogProps | null>(
        null,
    );
    const promiseRef = React.useRef<null | {
        resolve: (val: "cancel" | "confirm") => void;
        reject: () => void;
    }>(null);

    const handleShow: (p: DialogProps) => Promise<"cancel" | "confirm"> =
        React.useCallback((props: DialogProps) => {
            setDialogProps(props);
            setActive(true);

            return new Promise((resolve, reject) => {
                promiseRef.current = { resolve, reject };
            });
        }, []);

    const handleClose = React.useCallback(() => {
        setActive(false);
        setDialogProps(null);
    }, []);

    const handleConfirm = React.useCallback(
        (success: boolean) => {
            if (promiseRef.current) {
                promiseRef.current.resolve(success ? "confirm" : "cancel");
            }
            handleClose();
        },
        [handleClose],
    );

    const contextValue = React.useMemo(
        () => ({ show: handleShow, active }),
        [active, handleShow],
    );

    return (
        <confirmationDialogContext.Provider value={contextValue}>
            {children}
            {dialogProps && active ? (
                <ConfirmationDialog
                    {...dialogProps}
                    onConfirm={handleConfirm}
                    onClose={handleClose}
                />
            ) : null}
        </confirmationDialogContext.Provider>
    );
};
