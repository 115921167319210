import { CampaignInviteStatus, CampaignRequestStatus } from "@lib/gql/graphql";
import { CampaignActivityStatus, CampaignStatus } from "@lib/types/campaign";
import { ColorSchema } from "@styles/theme/palette";

// Parses a string into a `CampaignStatus`. Returns `undefined` if the parsing
// failed to find a fitting enum variant.
export const campaignStatusFromString = (
    str: string,
): CampaignStatus | undefined => {
    const lower = str.toLowerCase();
    if (lower.includes("pending")) return CampaignStatus.Pending;
    if (lower.includes("active")) return CampaignStatus.Active;
    if (lower.includes("stopped")) return CampaignStatus.Stopped;
    if (lower.includes("denied")) return CampaignStatus.Denied;
    if (lower.includes("cancelled")) return CampaignStatus.Cancelled;
    return undefined;
};

export const isActiveOrStopped = (campaignStatus?: CampaignStatus): boolean => {
    if (!campaignStatus) return false;

    return (
        campaignStatus === CampaignStatus.Active ||
        campaignStatus === CampaignStatus.Stopped
    );
};

// Legacy status
const statusColors: { [key in CampaignStatus]: ColorSchema } = {
    [CampaignStatus.Active]: "success",
    [CampaignStatus.Pending]: "warning",
    [CampaignStatus.Stopped]: "error",
    [CampaignStatus.Denied]: "error",
    [CampaignStatus.Cancelled]: "info",
};

export const campaignStatusColor = (
    campaignStatus?: CampaignStatus,
): ColorSchema => (campaignStatus ? statusColors[campaignStatus] : "primary");

// GraphQl status
const requestStatusColors: {
    [key in CampaignRequestStatus]: ColorSchema;
} = {
    [CampaignRequestStatus.Active]: "success",
    [CampaignRequestStatus.Pending]: "warning",
    [CampaignRequestStatus.Stopped]: "error",
    [CampaignRequestStatus.Denied]: "error",
    [CampaignRequestStatus.Cancelled]: "info",
};

export const campaignRequestStatusColor = (
    campaignStatus?: CampaignRequestStatus,
): ColorSchema =>
    campaignStatus ? requestStatusColors[campaignStatus] : "primary";
// ----

const activityStatusColors: { [key in CampaignActivityStatus]: ColorSchema } = {
    [CampaignStatus.Active]: "success",
    [CampaignStatus.Stopped]: "error",
};

export const campaignActivityStatusColor = (
    status?: CampaignActivityStatus,
): ColorSchema => (status ? activityStatusColors[status] : "primary");

const campaignRequestStatusLabels: { [key in CampaignRequestStatus]: string } =
    {
        [CampaignRequestStatus.Active]: "Active",
        [CampaignRequestStatus.Pending]: "Pending",
        [CampaignRequestStatus.Stopped]: "Stopped",
        [CampaignRequestStatus.Denied]: "Denied",
        [CampaignRequestStatus.Cancelled]: "Cancelled",
    };

export const campaignRequestStatusLabel = (
    status: CampaignRequestStatus,
): string => campaignRequestStatusLabels[status];

const campaignInviteStatusColors: {
    [key in CampaignInviteStatus]: ColorSchema;
} = {
    [CampaignInviteStatus.Invited]: "warning",
    [CampaignInviteStatus.Accepted]: "success",
    [CampaignInviteStatus.Denied]: "error",
};

const campaignInviteStatusLabels: {
    [key in CampaignInviteStatus]: string;
} = {
    [CampaignInviteStatus.Invited]: "Invited",
    [CampaignInviteStatus.Accepted]: "Accepted",
    [CampaignInviteStatus.Denied]: "Denied",
};

export const campaignInviteStatusColor = (
    status: CampaignInviteStatus,
): ColorSchema => campaignInviteStatusColors[status];

export const campaignInviteStatusLabel = (
    status: CampaignInviteStatus,
): string => campaignInviteStatusLabels[status];
