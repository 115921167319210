import { LoadingButton } from "@mui/lab";
import {
    styled,
    alpha,
    Box,
    Alert,
    Stack,
    Theme,
    SxProps,
} from "@mui/material";

const ActionAlert: React.FC<{
    sx?: SxProps<Theme>;
    text: string;
    buttons: Array<{
        label: string;
        onClick: () => void;
        loading?: boolean;
    }>;
}> = ({ sx = {}, ...props }) => {
    return (
        <Alert
            severity="warning"
            sx={{
                "& .MuiAlert-message": {
                    width: "100%",
                },
                "& .MuiAlert-icon": {
                    alignItems: "center",
                },
                ...sx,
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                }}
            >
                {props.text}
                <Stack spacing={1} direction="row">
                    {props.buttons.map(({ label, onClick, loading }) => (
                        <DarkWarningButton
                            key={label}
                            loading={loading}
                            onClick={onClick}
                        >
                            {label}
                        </DarkWarningButton>
                    ))}
                </Stack>
            </Box>
        </Alert>
    );
};

const DarkWarningButton = styled(LoadingButton)`
    color: ${props => props.theme.palette.warning.darker};
    border-color: ${props => props.theme.palette.warning.darker};
    text-transform: none;

    &:hover {
        border-color: ${props => props.theme.palette.warning.darker};
        background-color: ${props =>
            alpha(props.theme.palette.warning.darker, 0.1)};
    }
`;

DarkWarningButton.defaultProps = {
    variant: "outlined",
    size: "small",
};

export default ActionAlert;
