import { User } from "@firebase/auth";
import { auth } from "@lib/firebase-internal/clientApp";
import { Role, AuthRole, RoleInfo } from "@lib/types/auth";
import { roleFromString } from "@utils/role";
import { hasValue } from "@utils/typescript";
import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";

export const getAuthRoleInfo = async (user: User) => {
    const tokenResult = await user.getIdTokenResult();
    const strings = (tokenResult.claims.roles ?? []) as string[];

    const roleInfo = tokenResult.claims.roleInfo as
        | Record<string, Record<string, unknown> | undefined>
        | undefined;

    return getAuthRolesFrom(strings, roleInfo);
};

export const getAuthRolesFrom = (
    roles: string[] | undefined,
    roleInfo: Record<string, Record<string, unknown> | undefined> | undefined,
) => {
    const infoMap = Object.keys(roleInfo ?? {}).reduce(
        (acc: Record<string, unknown>, key) => {
            acc[key.toLowerCase()] = roleInfo?.[key];
            return acc;
        },
        {},
    );

    const raw = (roles ?? []).map(s => ({
        roleStr: s,
        info: infoMap[s.toLowerCase()],
    }));

    const authRoles: AuthRole<Role>[] = raw
        .map(({ roleStr, info }) => {
            const role = roleFromString(roleStr);

            if (!role) {
                return;
            }

            return {
                role,
                info: role ? (info as RoleInfo[typeof role]) : undefined,
            };
        })
        .filter(hasValue);

    return authRoles;
};

const useAuthRoles = () => {
    const [user, userLoading] = useAuthState(auth);
    const [roleInfo, setRoleInfo] = React.useState<
        AuthRole<Role>[] | undefined
    >(undefined);

    React.useEffect(() => {
        if (userLoading) return;

        if (!user) {
            setRoleInfo([]);
            return;
        }

        getAuthRoleInfo(user).then(ri => setRoleInfo(ri));
    }, [user, userLoading]);

    return roleInfo;
};

export default useAuthRoles;
