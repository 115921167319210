import ImpersonateAlert from "@components/admin/impersonateAlert";
import { API_HOST_URL } from "@lib/constants";
import { auth } from "@lib/firebase-internal/clientApp";
import { authenticatedFetcher } from "@utils/fetcher";
import { signInWithCustomToken } from "firebase/auth";
import { useRouter } from "next/router";
import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";

export const getImpersonationToken = async (
    authToken: string,
    userIdToImpersonate: string,
): Promise<
    { impersonationToken: string; reLoginToken: string } | undefined
> => {
    const fetcher = authenticatedFetcher(authToken, "POST");
    const url = `${API_HOST_URL}/impersonate/${encodeURIComponent(
        userIdToImpersonate,
    )}`;

    const res = await fetcher(url);

    if (!res.ok) {
        return;
    }

    return await res.json();
};

export const useImpersonation = (): [
    (userId: string) => Promise<void>,
    boolean,
] => {
    const [loading, setLoading] = React.useState(false);
    const [user] = useAuthState(auth);
    const { replace } = useRouter();
    const { setPreImpersonationAuthToken } =
        React.useContext(impersonationContext);

    const impersonate = React.useCallback(
        async (userId: string) => {
            if (!user || loading || !userId) {
                return;
            }

            setLoading(true);

            try {
                const authToken = await user.getIdToken();
                const tokens = await getImpersonationToken(authToken, userId);

                if (!tokens) {
                    return;
                }

                const { impersonationToken, reLoginToken } = tokens;

                setPreImpersonationAuthToken(reLoginToken);
                await signInWithCustomToken(auth, impersonationToken);
                replace("/");
            } finally {
                setLoading(false);
            }
        },
        [setLoading, loading, replace, user, setPreImpersonationAuthToken],
    );

    return [impersonate, loading];
};

interface ImpersonationContextProps {
    preImpersonationAuthToken: string | undefined;
    setPreImpersonationAuthToken: (authToken: string | undefined) => void;
    loginWithPreImpersonationToken: () => Promise<void>;
}

const AUTH_TOKEN_KEY = "preImpersonationAuthToken";

const getPreImpersonationAuthToken = () =>
    typeof localStorage !== "undefined"
        ? localStorage.getItem(AUTH_TOKEN_KEY) ?? undefined
        : undefined;

export const usePreImpersonationAuthToken = (): ImpersonationContextProps => {
    const [storedAuthToken, setStoredAuthToken] = React.useState<
        string | undefined
    >();
    const { replace } = useRouter();

    React.useEffect(() => {
        setStoredAuthToken(getPreImpersonationAuthToken());
    }, []);

    const setPreImpersonationAuthToken = (authToken: string | undefined) => {
        if (authToken) {
            localStorage.setItem(AUTH_TOKEN_KEY, authToken);
        } else {
            localStorage.removeItem(AUTH_TOKEN_KEY);
        }
        setStoredAuthToken(authToken);
    };

    const loginWithPreImpersonationToken = async () => {
        const authToken = getPreImpersonationAuthToken();

        if (!authToken) {
            auth.signOut();
            return;
        }

        await signInWithCustomToken(auth, authToken).catch(() =>
            auth.signOut(),
        );
        setPreImpersonationAuthToken(undefined);
        replace("/");
    };

    React.useEffect(() => {
        const unsub = auth.onAuthStateChanged(user => {
            if (user === null) {
                localStorage.removeItem(AUTH_TOKEN_KEY);
                setStoredAuthToken(undefined);
            }

            return user;
        });

        return () => unsub();
    }, []);

    return {
        preImpersonationAuthToken: storedAuthToken,
        setPreImpersonationAuthToken,
        loginWithPreImpersonationToken,
    };
};

export const impersonationContext =
    React.createContext<ImpersonationContextProps>({
        preImpersonationAuthToken: undefined,
        loginWithPreImpersonationToken: () => Promise.resolve(),
        setPreImpersonationAuthToken: () => ({}),
    });

export const ImpersonationContextProvider: React.FC<
    React.PropsWithChildren<unknown>
> = ({ children }) => {
    const impersonationContextValue = usePreImpersonationAuthToken();

    return (
        <impersonationContext.Provider value={impersonationContextValue}>
            {children}
            <ImpersonateAlert />
        </impersonationContext.Provider>
    );
};

export default useImpersonation;
