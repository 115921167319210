import { Role } from "@lib/types/auth";

const ROLE_MAP: Record<string, Role | undefined> = {
    campaignmanager: Role.CampaignManager,
    companycampaignmanager: Role.CompanyCampaignManager,
    companyowner: Role.CompanyOwner,
};

export const roleFromString = (str: string) =>
    ROLE_MAP[str.toLowerCase().trim()];

export const rolesFromStrings = (strings: string[]) =>
    strings.map(roleFromString);
