import React from "react";
import { setUser } from ".";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "@lib/firebase-internal/clientApp";

const useUpdateUserId = () => {
    const [user] = useAuthState(auth);

    React.useEffect(() => {
        if (!user) {
            return;
        }

        setUser(user.uid);
    }, [user]);
};

export default useUpdateUserId;
