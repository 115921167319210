import { useRouter } from "next/router";
import React from "react";
import useAuthRoles from "./roles/useAuthRoles";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "@lib/firebase-internal/clientApp";
import useCompanySubscription from "./useCompanySubscription";

export const DRAW_SUBSCRIPTION_PATH = "/company/draw-subscription";

const useRedirectCompanyToBilling = (): void => {
    const [user, userLoading] = useAuthState(auth);
    const rolesInfo = useAuthRoles();
    const companyId = React.useMemo(
        () => rolesInfo?.find(r => r.info?.companyId)?.info?.companyId,
        [rolesInfo],
    );

    const [subscription, fetching] = useCompanySubscription();

    const { replace } = useRouter();

    React.useEffect(() => {
        // Don't do anything if the data or window is not fetched, or if there is no subscription
        if (subscription || !user || typeof window === "undefined") {
            return;
        }
        // If the data is fetched and the company do not have a subscription redirect to draw-subscription site
        if (
            !fetching &&
            companyId &&
            (subscription === null || subscription === undefined) &&
            //Prevents infinity loop
            window.location.pathname !== DRAW_SUBSCRIPTION_PATH
        ) {
            replace(DRAW_SUBSCRIPTION_PATH);
        }
    }, [subscription, fetching, user, companyId, replace, userLoading]);

    return;
};

export default useRedirectCompanyToBilling;
