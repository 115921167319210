import {
    Commission,
    CommissionInfo,
    CommissionInput,
    MultiCurrencyMoney,
} from "@lib/gql/graphql";
import { CommissionType, Cpa, Money, Percentage } from "@lib/types/campaign";
import {
    Cpa as CpaStringCurrency,
    Percentage as PercentageStringCurrency,
} from "@lib/gql/graphql";
import { SupportedCurrency } from "@lib/types/currency";
import { sanitizeFloatingPoint } from "./typescript";
interface MoneyToStringOptions {
    minimumFractionDigits?: number;
    conversionFactor?: number;
}

export type MoneyFormatter = (
    money: Money,
    options?: MoneyToStringOptions,
) => string;

export type PercentageFormatter = (
    percentage: Percentage | number,
    precision?: number,
) => string;

export interface CpaToStringOptions {
    precision?: number;
}

export type CpaFormatter = (cpa: Cpa, options?: CpaToStringOptions) => string;
/**
 * Formats a money value to a string based on the browser `navigator.language`.
 * If you need to do SSR with this function, see `useMoneyFormatter` instead.
 * From previous experience, don't do a `typeof window === undefined` check
 * with this function, because you will get a hydration error.
 */
export const moneyToString: MoneyFormatter = (
    money,
    { conversionFactor = 1, minimumFractionDigits } = {},
) => {
    return (money.amount * conversionFactor).toLocaleString(
        navigator.language,
        {
            style: "currency",
            currency: money.currency,
            maximumFractionDigits:
                Number.isInteger(money.amount) && !minimumFractionDigits
                    ? 0
                    : minimumFractionDigits,
            minimumFractionDigits: minimumFractionDigits
                ? minimumFractionDigits
                : 0,
        },
    );
};

export const cpaToString: CpaFormatter = (cpa: Cpa, options) => {
    if (isPercentage(cpa))
        return percentageToString(cpa.percentage, options?.precision);

    return cpa.amount.toLocaleString(navigator.language, {
        style: "currency",
        currency: cpa.currency,
        maximumFractionDigits:
            Number.isInteger(cpa.amount) && !options?.precision
                ? 0
                : options?.precision,
        minimumFractionDigits: options?.precision ? options.precision : 0,
    });
};

export type CommissionFormatter = (
    commission: Commission,
    options?: CpaToStringOptions,
) => string;

export const commissionToString: CommissionFormatter = (
    commission: Commission,
    options,
) => {
    const cpa = commissionToCpa(commission);
    return cpa ? cpaToString(cpa, options) : "";
};

export const calcEarningsString = (
    cpa: Money,
    conversions: number,
    options?: CpaToStringOptions,
) => {
    return (cpa.amount * conversions).toLocaleString(navigator.language, {
        style: "currency",
        currency: cpa.currency,
        maximumFractionDigits:
            Number.isInteger(cpa.amount) && !options?.precision
                ? 0
                : options?.precision,
        minimumFractionDigits: options?.precision ? options.precision : 0,
    });
};

export const percentageToString: PercentageFormatter = (p, precision) => {
    return `${(ensurePercentage(p) * 100).toFixed(precision)}%`;
};

export const isMoney = (money: unknown): money is Money => {
    const hasMoneyKeys =
        typeof money === "object" &&
        money !== null &&
        "amount" in money &&
        "currency" in money;
    if (!hasMoneyKeys) return false;
    const moneyWithKeys = money as { amount: unknown; currency: unknown };
    return (
        typeof moneyWithKeys.amount === "number" &&
        typeof moneyWithKeys.currency === "string"
    );
};

export const isPercentage = (percent: unknown): percent is Percentage => {
    const hasPercentKeys =
        percent !== null &&
        typeof percent === "object" &&
        "percentage" in percent;
    if (!hasPercentKeys) return false;
    const percentWithKeys = percent as { percentage: unknown };
    return typeof percentWithKeys.percentage === "number";
};

export const ensurePercentage = (percent: Percentage | number) => {
    if (!percent) return 0;
    const p = isPercentage(percent) ? percent.percentage : percent;
    return p > 1 ? p / 100 : p;
};

export function commissionToCpa(commission: Commission): Cpa;
export function commissionToCpa(commission: null | undefined): undefined;
export function commissionToCpa(
    commission: Commission | null | undefined,
): Cpa | undefined {
    if (isMoney(commission)) return commission;
    if (isPercentage(commission)) return commission;
    return undefined;
}

export const fromLegacyCpa = (cpa: CpaStringCurrency): Cpa | undefined => {
    if (
        Object.values(SupportedCurrency).includes(
            cpa.currency as SupportedCurrency,
        )
    ) {
        return {
            amount: cpa.amount,
            currency: cpa.currency as SupportedCurrency,
        };
    }
    return undefined;
};

export const replaceCommissionAmount = (
    commision: Commission,
    amount: number,
): Commission => {
    return isMoney(commision)
        ? {
              amount: amount,
              currency: commision.currency,
          }
        : { percentage: amount };
};

export const calcCurrentCommission = ({
    campaignCommission,
    commissionInfo,
}: {
    campaignCommission?: Commission;
    commissionInfo: CommissionInfo;
}): Commission | undefined => {
    const today = new Date();

    const existsCustomCurrentWithPastStartDate =
        commissionInfo.currentCommissionStartTimestamp &&
        new Date(commissionInfo.currentCommissionStartTimestamp) <= today;

    const existsCustomCurrentWithNoStartDate =
        !commissionInfo.currentCommissionStartTimestamp;

    if (
        commissionInfo.current &&
        (existsCustomCurrentWithPastStartDate ||
            existsCustomCurrentWithNoStartDate)
    ) {
        return commissionInfo.current;
    }

    const currentCommission = commissionInfo.history.find(commission => {
        const startDate = new Date(commission.startTimestamp);
        const endDate = new Date(commission.endTimestamp);
        return today >= startDate && today <= endDate;
    });

    if (currentCommission) return currentCommission.commission;

    return campaignCommission;
};

export const getPerUnitLabel = (cpa: Cpa | Commission) => {
    if (isMoney(cpa)) return "Per conversion";
    if (isPercentage(cpa)) return "Commission of sale";
    return "";
};

export const commissionSortComparator = (a: unknown, b: unknown) => {
    return isMoney(a) && isMoney(b)
        ? a.amount - b.amount
        : isPercentage(a) && isPercentage(b)
          ? a.percentage - b.percentage
          : 0;
};

export const getCommissionType = (
    commission: Commission | undefined,
): CommissionType | undefined => {
    if (!commission) return undefined;
    return isPercentage(commission)
        ? CommissionType.RevenueShare
        : CommissionType.FixedAmount;
};

export const commissionToNumber = (commission: Commission | undefined) => {
    if (isMoney(commission)) return commission.amount;
    if (isPercentage(commission))
        return sanitizeFloatingPoint(commission.percentage * 100);
    return 0;
};

export const addToMultiCurrency = (currencies: Money[], toAdd: Money) => {
    if (toAdd.amount == 0.0) {
        return;
    }

    const found = currencies.find(c => c.currency === toAdd.currency);

    if (!found) {
        currencies.push({ ...toAdd });
        return;
    }

    found.amount += toAdd.amount;
};

export const combineMultiCurrencies = (
    a: MultiCurrencyMoney,
    b: MultiCurrencyMoney,
) => {
    const result: Money[] = [];
    a.currencies.forEach(m => addToMultiCurrency(result, m));
    b.currencies.forEach(m => addToMultiCurrency(result, m));
    return { currencies: result };
};

export function convertToCommissionInput(
    invoiceCommission: CpaStringCurrency | PercentageStringCurrency,
): CommissionInput {
    if (invoiceCommission.__typename === "Cpa") {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { __typename, ...rest } = invoiceCommission;
        return {
            cpa: isMoney(rest) ? rest : undefined,
        };
    }

    if (invoiceCommission.__typename === "Percentage") {
        return {
            percentage: invoiceCommission.percentage,
        };
    }

    return {};
}
export const percentageToIntegerRepresentation = (percentage: number) => {
    // toFixed(0) is used because floating point errors occurs,
    // leading to a lot of 0 decimals followed by a number
    return (percentage * 100).toFixed(0);
};
