import { auth } from "@lib/firebase-internal/clientApp";
import type { ClientOptions } from "urql";

const url = process.env.NEXT_PUBLIC_GQL_ENDPOINT ?? "http://localhost:5050";

let authInitialized = false;

const authPromise = new Promise<void>(resolve => {
    auth.onAuthStateChanged(s => {
        resolve();
        authInitialized = true;
        return s;
    });
});

export const clientOptions: ClientOptions = {
    url,
    fetch: async (input, init) => {
        if (!authInitialized) {
            await authPromise;
        }

        const token = await auth.currentUser?.getIdToken();

        return fetch(input, {
            ...init,
            headers: {
                ...init?.headers,
                authorization: token ? `Bearer ${token}` : "",
            },
        });
    },
};
