import { initializeApp } from "firebase/app";
import {
    browserLocalPersistence,
    getAuth,
    setPersistence,
    connectAuthEmulator,
} from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getStorage, connectStorageEmulator } from "firebase/storage";

const firebaseConfig = {
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
    measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
};

const firebaseClient = initializeApp(firebaseConfig);

// eslint-disable-next-line valid-typeof
if (typeof window !== undefined) {
    setPersistence(getAuth(firebaseClient), browserLocalPersistence);
}

const db = getFirestore(firebaseClient);
const auth = getAuth(firebaseClient);
const storage = getStorage(firebaseClient);

if (process.env.NEXT_PUBLIC_ENV === "development") {
    try {
        connectFirestoreEmulator(db, "localhost", 8080);
        connectAuthEmulator(auth, "http://localhost:9099");
        connectStorageEmulator(storage, "localhost", 9199);
    } catch (e) {
        // ignore failure to connect to emulator. this will happen often when
        // using hot reloading, because this will attempt to modify firebase
        // settings after initialization.
    }
}

export default firebaseClient;
export { db, auth, storage };
