import React from "react";
import NextLink from "next/link";
import { Link, Toolbar } from "@mui/material";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import HomeIcon from "@mui/icons-material/HomeOutlined";
import ExploreIcon from "@mui/icons-material/ExploreOutlined";
import PaymentsIcon from "@mui/icons-material/AttachMoney";
import LoginIcon from "@mui/icons-material/Login";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import { NavSectionVertical } from "@components/mui/nav-section";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "@lib/firebase-internal/clientApp";
import useHasRole from "@hooks/roles/useHasRole";
import { Role } from "@lib/types/auth";
import { NavListProps } from "./mui/nav-section/type";
import useHasCampaignWithAutoPayout from "@hooks/useHasCampaignWithAutoPayout";
import FilifyTextIcon from "./icons/filifyText";
import { useRouter } from "next/router";
import { loginUrlWithRedirect, signupUrlWithRedirect } from "@utils/login";
import LoadingPage from "./utils/LoadingPage";
import {
    AccountBalanceTwoTone,
    AnalyticsOutlined,
    PersonOutlined,
    WorkOutline,
    MailOutline,
    CreditCard,
    PeopleAltOutlined,
    EmailOutlined,
    HelpOutlineOutlined,
    EditNoteOutlined,
    ChecklistOutlined,
    DomainAddOutlined,
} from "@mui/icons-material";

type NavConfig = {
    subheader: string;
    items: NavListProps[];
}[];

const NavSection: React.FC = () => {
    const [user, userLoading] = useAuthState(auth);
    const [isCampaignManager, cmLoading] = useHasRole(Role.CampaignManager);
    const [isCompanyCampaignManager, ccmLoading] = useHasRole(
        Role.CompanyCampaignManager,
    );
    const [isCompanyOwner, coLoading] = useHasRole(Role.CompanyOwner);
    const [navConfig, setNavConfig] = React.useState<NavConfig>([]);

    const [hasAutoPaymentCampaign, paymentsLoading] =
        useHasCampaignWithAutoPayout();

    const hasAccessToCompany = React.useMemo(() => {
        return isCompanyCampaignManager || isCompanyOwner;
    }, [isCompanyCampaignManager, isCompanyOwner]);

    const { asPath } = useRouter();
    const loginUrl = loginUrlWithRedirect(asPath);
    const signupUrl = signupUrlWithRedirect(asPath);

    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        setLoading(
            cmLoading ||
                coLoading ||
                ccmLoading ||
                userLoading ||
                paymentsLoading,
        );
    }, [cmLoading, coLoading, ccmLoading, userLoading, paymentsLoading]);

    React.useEffect(() => {
        const getAnonNavItems = (): NavConfig => {
            return [
                {
                    subheader: "",
                    items: [
                        {
                            title: "Discover",
                            path: "/discover",
                            icon: <ExploreIcon />,
                        },
                        {
                            title: "Sign up",
                            path: signupUrl,
                            icon: <HowToRegIcon />,
                        },
                        {
                            title: "Login",
                            path: loginUrl,
                            icon: <LoginIcon />,
                        },
                    ],
                },
            ];
        };

        const getCreatorNavItems = (): NavConfig => {
            return [
                {
                    subheader: "",
                    items: [
                        {
                            title: "Home",
                            path: "/",
                            icon: <HomeIcon />,
                        },
                        {
                            title: "Discover",
                            path: "/discover",
                            icon: <ExploreIcon />,
                        },
                        {
                            title: "Settings",
                            path: "/user/general",
                            icon: <SettingsIcon />,
                        },
                        {
                            title: "Payments",
                            path: "/payments",
                            icon: <PaymentsIcon />,
                            disabled: !hasAutoPaymentCampaign,
                        },
                    ],
                },
            ];
        };

        const getCompanyNavItems = (): NavConfig => {
            return [
                {
                    subheader: "Management",
                    items: [
                        {
                            title: "Campaigns",
                            path: "/company/campaigns",
                            icon: <AnalyticsOutlined />,
                        },
                        {
                            title: "Team",
                            path: "/company/campaign-managers",
                            icon: <AccountBalanceTwoTone />,
                        },
                        {
                            title: "Find Creators",
                            path: "/company/discover-creators",
                            icon: <ExploreIcon />,
                        },
                        {
                            title: "Company settings",
                            path: "/company/manage-company",
                            icon: <WorkOutline />,
                            disabled: !isCompanyOwner,
                        },
                        {
                            title: "My profile",
                            path: "/company/user/general",
                            icon: <PersonOutlined />,
                        },
                        {
                            title: "Message Templates",
                            path: "/company/message-templates",
                            icon: <MailOutline />,
                        },
                        {
                            title: "Billing",
                            path: "/company/billing",
                            icon: <CreditCard />,
                        },
                    ],
                },
            ];
        };

        const getAdminNavItems = (): NavConfig => {
            return [
                {
                    subheader: "Campaign Manager",
                    items: [
                        {
                            title: "Companies",
                            path: "/admin/companies",
                            icon: <AnalyticsOutlined />,
                        },
                        {
                            title: "Find Creators",
                            path: "/company/discover-creators",
                            icon: <ExploreIcon />,
                        },
                        {
                            title: "Campaign requests",
                            path: "/admin/campaign-requests",
                            icon: <ChecklistOutlined />,
                        },
                        {
                            title: "Edit campaigns",
                            path: "/admin/manage-campaign",
                            icon: <EditNoteOutlined />,
                        },
                        {
                            title: "Edit companies",
                            path: "/admin/manage-company",
                            icon: <DomainAddOutlined />,
                        },
                        {
                            title: "Message templates",
                            path: "/admin/message-templates",
                            icon: <EmailOutlined />,
                        },
                        {
                            title: "All users",
                            path: "/admin/all-users",
                            icon: <PeopleAltOutlined />,
                        },
                        {
                            title: "Create FAQ",
                            path: "/admin/create-faq",
                            icon: <HelpOutlineOutlined />,
                        },
                    ],
                },
            ];
        };

        const items: NavConfig = [];

        if (!hasAccessToCompany) {
            !user
                ? items.push(...getAnonNavItems())
                : items.push(...getCreatorNavItems());
        }

        if (hasAccessToCompany) items.push(...getCompanyNavItems());

        if (isCampaignManager) items.push(...getAdminNavItems());

        setNavConfig(items);
    }, [
        user,
        isCampaignManager,
        hasAutoPaymentCampaign,
        hasAccessToCompany,
        isCompanyOwner,
        loginUrl,
        signupUrl,
    ]);

    return loading ? (
        <LoadingPage />
    ) : (
        <NavSectionVertical navConfig={navConfig} />
    );
};

const SidebarContent: React.FC = () => {
    const [user] = useAuthState(auth);

    const iconLink = React.useMemo(
        () => (user ? "/" : "https://www.filify.app"),
        [user],
    );

    return (
        <>
            <Toolbar sx={{ p: 2.5 }}>
                <NextLink href={iconLink} passHref legacyBehavior>
                    <Link>
                        <FilifyTextIcon
                            sx={{ height: 46, width: "100%", mt: 2 }}
                        />
                    </Link>
                </NextLink>
            </Toolbar>
            <NavSection />
        </>
    );
};

export default SidebarContent;
