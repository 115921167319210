import { Label } from "@components/mui/Label";
import { SupportedCurrency } from "@lib/types/currency";
import { ChevronRightRounded } from "@mui/icons-material";
import {
    Avatar,
    ButtonBase,
    Card,
    CardContent,
    Skeleton,
    Stack,
    Typography,
} from "@mui/material";
import { campaignRequestStatusColor } from "@utils/campaignStatus";
import { moneyToString } from "@utils/currency";
import { createCampaignStatsLink } from "@utils/links";
import NextLink from "next/link";
import React from "react";
import { CampaignRow } from "./campaignsOverview";

const MobileCampaignsOverview: React.FC<{
    rows: CampaignRow[];
    loading: boolean;
}> = ({ rows, loading }) => (
    <Card>
        <CardContent sx={{ p: 1 }}>
            <Stack>
                <Stack p={2} pb={1.5}>
                    <Typography variant="h6">Your campaigns</Typography>
                    <Typography variant="body2" color="text.secondary">
                        You have{" "}
                        {loading ? (
                            <Skeleton
                                width={10}
                                sx={{ display: "inline-block" }}
                            />
                        ) : (
                            rows.length
                        )}{" "}
                        active campaigns
                    </Typography>
                </Stack>
                {rows.map(row => (
                    <MobileItem
                        row={row}
                        key={row.campaignRequest.campaign.id}
                    />
                ))}
                {loading ? (
                    <>
                        <MobileItem />
                        <MobileItem />
                    </>
                ) : null}
            </Stack>
        </CardContent>
    </Card>
);

const MobileItem: React.FC<{ row?: CampaignRow }> = ({ row }) => (
    <NextLink
        href={
            row ? createCampaignStatsLink(row.campaignRequest.campaign.id) : ""
        }
        passHref
        legacyBehavior
    >
        <ButtonBase
            sx={{
                flexDirection: "row",
                pb: 1.5,
                pt: 1.5,
                p: 2,
                justifyContent: "space-between",
                alignItems: "center",
                borderRadius: 1,
                cursor: "pointer",
                "&:hover": {
                    bgcolor: "background.neutral",
                },
            }}
            disabled={!row}
        >
            <Stack direction="row" spacing={2} alignItems="center">
                {!row ? (
                    <Skeleton variant="circular" width={40} height={40} />
                ) : (
                    <Avatar
                        src={row.campaignRequest.campaign.company.imageUrl}
                    />
                )}
                <Stack>
                    <Stack direction="row" spacing={1}>
                        <Typography variant="subtitle2" textAlign="start">
                            {!row ? (
                                <Skeleton width={150} />
                            ) : (
                                row.campaignRequest.campaign.company.name
                            )}
                        </Typography>
                        {row ? (
                            <Label
                                color={campaignRequestStatusColor(
                                    row?.campaignRequest.status,
                                )}
                            >
                                {row?.campaignRequest.status}
                            </Label>
                        ) : null}
                    </Stack>
                    <Typography variant="body2" color="text.secondary">
                        {!row ? (
                            <Skeleton width={200} />
                        ) : (
                            `${(row.stats.earnings.currencies.length > 0
                                ? row.stats.earnings.currencies
                                : [
                                      {
                                          amount: 0,
                                          currency: SupportedCurrency.EUR,
                                      },
                                  ]
                            )
                                .map(m => moneyToString(m))
                                .join("\n")} total earnings`
                        )}
                    </Typography>
                </Stack>
            </Stack>
            <ChevronRightRounded />
        </ButtonBase>
    </NextLink>
);

export default MobileCampaignsOverview;
