import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

const FilifyTextIcon: React.FC<SvgIconProps> = props => (
    <SvgIcon
        {...props}
        viewBox="0 0 147 46"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M39.6364 36.3815V1.97168H62.1804V7.95913H46.8257V16.1588H60.6806V22.1463H46.8257V36.3575H39.6364V36.3815Z"
            fill="#1482FC"
        />
        <path
            d="M70.4647 7.23785C69.4173 7.23785 68.5127 6.87716 67.7509 6.17982C66.9891 5.45844 66.6082 4.61683 66.6082 3.6069C66.6082 2.62101 66.9891 1.7794 67.7509 1.05802C68.5127 0.336644 69.3935 0 70.4409 0C71.5122 0 72.4168 0.36069 73.1548 1.05802C73.8927 1.7794 74.2736 2.62101 74.2736 3.6069C74.2736 4.59279 73.8927 5.45844 73.1548 6.17982C72.4168 6.87716 71.5122 7.23785 70.4647 7.23785ZM66.8939 36.3816V10.5562H73.988V36.3576H66.8939V36.3816Z"
            fill="#1482FC"
        />
        <path
            d="M86.7241 1.97168V36.3815H79.63V1.97168H86.7241Z"
            fill="#1482FC"
        />
        <path
            d="M95.9368 7.23785C94.8894 7.23785 93.9848 6.87716 93.223 6.17982C92.4612 5.45844 92.0803 4.61683 92.0803 3.6069C92.0803 2.62101 92.4612 1.7794 93.223 1.05802C93.9848 0.336644 94.8656 0 95.913 0C96.9843 0 97.8889 0.36069 98.6269 1.05802C99.3648 1.7794 99.7457 2.62101 99.7457 3.6069C99.7457 4.59279 99.3648 5.45844 98.6269 6.17982C97.9127 6.87716 97.0081 7.23785 95.9368 7.23785ZM92.366 36.3816V10.5562H99.4601V36.3576H92.366V36.3816Z"
            fill="#1482FC"
        />
        <path
            d="M118.838 10.5563V15.9426H103.079V10.5563H118.838ZM106.697 36.3817V8.7048C106.697 6.82921 107.054 5.29027 107.792 4.03988C108.53 2.81353 109.506 1.87574 110.768 1.27459C112.029 0.649392 113.458 0.36084 115.077 0.36084C116.172 0.36084 117.148 0.432978 118.052 0.6013C118.957 0.769622 119.624 0.913898 120.076 1.05817L118.814 6.44448C118.528 6.34829 118.195 6.27616 117.79 6.20402C117.386 6.13188 116.981 6.08379 116.553 6.08379C115.505 6.08379 114.791 6.32425 114.362 6.80517C113.958 7.28609 113.744 7.98342 113.744 8.84908V36.3817H106.697Z"
            fill="#1482FC"
        />
        <path
            d="M127.551 46C126.646 46 125.813 45.9278 125.027 45.7835C124.242 45.6393 123.599 45.4709 123.099 45.2786L124.694 39.9163C125.956 40.3011 127.003 40.4213 127.86 40.2289C128.717 40.0365 129.408 39.3633 129.907 38.209L130.312 37.127L121.147 10.5562H128.598L133.883 29.5044H134.145L139.477 10.5562L146.976 10.5802L137.049 39.1468C136.573 40.5175 135.93 41.7198 135.121 42.7537C134.311 43.7877 133.288 44.5812 132.026 45.1343C130.788 45.7114 129.289 46 127.551 46Z"
            fill="#1482FC"
        />
        <path
            d="M23.4962 26.1862L17.4019 20.0304L27.9716 9.354L31.4949 18.1067L23.4962 26.1862Z"
            fill="#1482FC"
        />
        <path
            d="M14.5452 17.145L8.45099 10.9893L19.2826 0.0483398L30.2808 1.25064L14.5452 17.145Z"
            fill="#1482FC"
        />
        <path
            d="M6.09489 13.7063L0.00134277 19.8613L18.08 38.1225L24.1735 31.9674L6.09489 13.7063Z"
            fill="#1482FC"
        />
    </SvgIcon>
);

export default FilifyTextIcon;
