export function isObject<T>(item: T) {
    return item && typeof item === "object" && !Array.isArray(item);
}

export const getKeys = <T extends object>(item: T): Array<keyof T> =>
    Object.keys(item) as Array<keyof T>;

export interface RecursiveObject<T> {
    [key: string]: T | unknown;
}

// https://stackoverflow.com/a/37164538
// Please, disregard the type signatures here. They are WONKY, and I honestly
// just added them in a way that would choke the TS compiler errors.
export default function mergeDeep<TypeA, TypeB>(
    target: RecursiveObject<TypeA>,
    source: RecursiveObject<TypeB>,
): TypeA {
    const output = Object.assign({}, target);
    if (isObject(target) && isObject(source)) {
        Object.keys(source).forEach(key => {
            if (isObject(source[key])) {
                if (!(key in target))
                    Object.assign(output, { [key]: source[key] });
                else
                    output[key] = mergeDeep(
                        target[key] as RecursiveObject<TypeA>,
                        source[key] as RecursiveObject<TypeB>,
                    );
            } else {
                Object.assign(output, { [key]: source[key] });
            }
        });
    }
    return output as unknown as TypeA;
}
