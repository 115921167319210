import { Timeframe } from "@components/user/dashboard/campaignsPerformanceChart";
import { graphql } from "@lib/gql";
import { GetMyInfluencerStatisticsQuery } from "@lib/gql/graphql";
import {
    addDays,
    addMonths,
    endOfDay,
    format,
    startOfDay,
    startOfMonth,
    subMonths,
    subWeeks,
    subYears,
} from "date-fns";
import { useQuery } from "urql";

const GetInfluencerPerformanceQuery = graphql(`
    query GetMyInfluencerStatistics(
        $campaignId: Id
        $toDateTime: DateTime!
        $fromDateTime: DateTime!
        $toDate: NaiveDate!
        $fromDate: NaiveDate!
    ) {
        me {
            influencerCampaignStatistics {
                campaignPerformanceTune(
                    fromDate: $fromDateTime
                    toDate: $toDateTime
                    campaignId: $campaignId
                ) {
                    totals {
                        clicks
                        conversions
                        influencerEarnings {
                            currencies
                        }
                    }
                    byDate {
                        date
                        conversions
                        clicks
                        earnings {
                            currencies
                        }
                    }
                    byCampaign {
                        campaignId
                        conversions
                        clicks
                        earnings {
                            currencies
                        }
                    }
                }
                campaignPerformanceManual(
                    fromDate: $fromDate
                    toDate: $toDate
                    campaignId: $campaignId
                ) {
                    totals {
                        clicks
                        conversions
                        influencerEarnings {
                            currencies
                        }
                    }
                    byDate {
                        date
                        conversions
                        clicks
                        earnings {
                            currencies
                        }
                    }
                    byCampaign {
                        campaignId
                        conversions
                        clicks
                        earnings {
                            currencies
                        }
                    }
                }
            }
        }
    }
`);

export type InfluencerPerformanceData = {
    dateRange: {
        fromTimestamp: Date;
        toTimestamp: Date;
    };
    statistics: GetMyInfluencerStatisticsQuery["me"]["influencerCampaignStatistics"];
};

const useUsersCampaignData = (options: {
    campaignId?: string;
    shouldWait?: boolean;
    timeframe: Timeframe;
}) => {
    const { fromTimestamp, toTimestamp } = makeDateRangeFromTimeframe(
        options.timeframe,
    );

    const [{ data, fetching, error }] = useQuery({
        query: GetInfluencerPerformanceQuery,
        variables: {
            campaignId: options.campaignId,
            fromDateTime: fromTimestamp.toISOString(),
            toDateTime: toTimestamp.toISOString(),
            fromDate: format(fromTimestamp, "yyyy-MM-dd"),
            toDate: format(toTimestamp, "yyyy-MM-dd"),
        },
        pause: options.shouldWait,
    });

    const influencerStats: InfluencerPerformanceData["statistics"] | undefined =
        data?.me?.influencerCampaignStatistics;

    return {
        data: influencerStats
            ? {
                  statistics: influencerStats,
                  dateRange: { fromTimestamp, toTimestamp },
              }
            : undefined,
        fetching,
        error,
    };
};

const makeDateRangeFromTimeframe = (timeframe: Timeframe) => {
    const now = new Date();

    switch (timeframe) {
        case Timeframe.Week:
            return {
                fromTimestamp: startOfDay(addDays(subWeeks(now, 1), 1)),
                toTimestamp: endOfDay(now),
            };
        case Timeframe.Month:
            return {
                fromTimestamp: startOfDay(addDays(subMonths(now, 1), 1)),
                toTimestamp: endOfDay(now),
            };
        case Timeframe.Year:
            return {
                fromTimestamp: startOfMonth(addMonths(subYears(now, 1), 1)),
                toTimestamp: endOfDay(now),
            };
    }
};

export default useUsersCampaignData;
