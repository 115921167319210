import React from "react";
import NextLink from "next/link";
import { useRouter } from "next/router";
import {
    Avatar,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import { auth } from "@lib/firebase-internal/clientApp";
import { useAuthState } from "react-firebase-hooks/auth";
import { signOut } from "firebase/auth";
import { useDocument } from "react-firebase-hooks/firestore";
import { db } from "@lib/firebase-internal/clientApp";
import { doc } from "@firebase/firestore";
import useHasRole from "@hooks/roles/useHasRole";
import { Role } from "@lib/types/auth";

export const UserAvatar: React.FC = () => {
    const theme = useTheme();
    const [user] = useAuthState(auth);
    const [userDocument] = useDocument(doc(db, "users", user?.uid ?? " "));
    if (!user) {
        return null;
    }

    return (
        <Avatar
            alt={userDocument?.data()?.displayName ?? undefined}
            src={userDocument?.data()?.photoURL ?? undefined}
            sx={{
                backgroundColor: !user.photoURL
                    ? theme.palette.primary.main
                    : null,
                color: theme.palette.background.default,
            }}
        />
    );
};

const UserMenuWrapper: React.FC<React.PropsWithChildren<unknown>> = ({
    children,
}) => {
    const theme = useTheme();
    const [hasAccessToCompany] = useHasRole([
        Role.CompanyOwner,
        Role.CompanyCampaignManager,
    ]);

    const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(
        null,
    );
    const menuIsOpen = Boolean(menuAnchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) =>
        setMenuAnchorEl(event.currentTarget);
    const handleClose = () => setMenuAnchorEl(null);

    const router = useRouter();
    const onLogout = React.useCallback(() => {
        signOut(auth);
        router.push("/login");
    }, [router]);

    return (
        <>
            <IconButton
                onClick={handleClick}
                aria-controls={menuIsOpen ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={menuIsOpen ? "true" : undefined}
            >
                {children}
            </IconButton>
            <Menu
                anchorEl={menuAnchorEl}
                id="account-menu"
                open={menuIsOpen}
                onClose={handleClose}
                onClick={handleClose}
                sx={{ boxShadow: theme.customShadows.z24 }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                <NextLink
                    href={
                        hasAccessToCompany
                            ? "/company/user/general"
                            : "/user/general"
                    }
                    passHref
                    legacyBehavior
                >
                    <MenuItem>
                        <ListItemIcon>
                            <SettingsIcon fontSize="small" />
                        </ListItemIcon>
                        Settings
                    </MenuItem>
                </NextLink>
                <MenuItem onClick={onLogout}>
                    <ListItemIcon>
                        <LogoutIcon fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </>
    );
};

export const UserAvatarWithMenu: React.FC<
    React.PropsWithChildren<unknown>
> = () => (
    <UserMenuWrapper>
        <UserAvatar />
    </UserMenuWrapper>
);
