import { Components, Theme } from "@mui/material/styles";

import Alert from "./alert";
import Avatar from "./avatar";
import Button from "./button";
import Card from "./card";
import Checkbox from "./checkbox";
import ControlLabel from "./controlLabel";
import CssBaseline from "./cssBaseline";
import DataGrid from "./dataGrid";
import Dialog from "./dialog";
import Input from "./input";
import Link from "./link";
import Radio from "./radio";
import Table from "./table";

const componentsOverride = (theme: Theme): Components =>
    Object.assign(
        Alert(theme),
        Avatar(theme),
        Button(theme),
        Card(theme),
        Checkbox(theme),
        ControlLabel(theme),
        CssBaseline(theme),
        DataGrid(theme),
        Dialog(theme),
        Input(theme),
        Link(),
        Radio(theme),
        Table(theme),
    );

export default componentsOverride;
