import {
    Alert,
    Button,
    Snackbar,
    styled,
    Typography,
    TypographyProps,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import React from "react";
import ReactPortal from "@components/utils/ReactPortal";

export const CopyableToClipboard: React.FC<{
    text: string;
    buttonLabel?: string;
    snackbar?: boolean;
    onOpen?: () => void;
    typographyVariant?: TypographyProps["variant"];
}> = ({
    text,
    snackbar = true,
    onOpen,
    typographyVariant = "body2",
    buttonLabel: label,
}) => {
    const copyTextToClipboard = React.useCallback(() => {
        navigator.clipboard.writeText(text);
        if (onOpen) onOpen();
        if (snackbar) setCopySnackbar(true);
    }, [onOpen, snackbar, text]);

    const [copySnackbarOpen, setCopySnackbar] = React.useState(false);

    return (
        <>
            <WrapperButton variant="text" onClick={copyTextToClipboard}>
                <Typography variant={typographyVariant}>
                    {label ?? text}
                </Typography>
                <ContentCopyIcon fontSize="small" />
            </WrapperButton>
            <ReactPortal wrapperId="snack-wrapper">
                <Snackbar
                    open={copySnackbarOpen}
                    autoHideDuration={2000}
                    onClose={() => setCopySnackbar(false)}
                >
                    <Alert severity="success">Copied to clipboard</Alert>
                </Snackbar>
            </ReactPortal>
        </>
    );
};

const WrapperButton = styled(Button)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-transform: none;
    height: 100%;

    // Is this a disgusting way to ignore the parent padding? Yes. Does it work? Also yes.
    margin-left: -10px;
    min-width: calc(100% + 20px);
`;
